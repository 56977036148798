.o-showhide{
	$_this: &;
	.o-collapselist__title-link{
		#{$_this}-hide{
			display: block;
		}
		#{$_this}-view{
			display: none;
		}
		&.collapsed{
			#{$_this}-hide{
				display: none;
			}
			#{$_this}-view{
				display: block;
			}
		}
	}
}