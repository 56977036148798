/* crit:start */
h1,h2,h3,h4,h5,h6{
	margin: 0;
}

// Mixin are from _tools.mixin.fontstyles.scss
h1{
	@include h1;
	font-weight: 700;
	font-family: $sommet;
	@include fzlh(60px, 72px);
	@include mq-down(sm){
		@include fzlh(52px, 64px);
	}
	@include mq-down(xs){
		@include fzlh(32px, 40px);
	}
}
h2{
	@include h2;
	font-family: $sommet;
	font-weight: 700;
	@include fzlh(26px, 36px);
}
h3{
	@include h3;
	font-family: $sommet;
	font-weight: 700;
	@include fzlh(20px, 28px);
}
h4{
	@include h4;
}
h5{
	@include h5;
}
h6{
	@include h6;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include mq-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.h1#{$infix} {
			@include h1;
		}

		.h2#{$infix} {
			@include h2;
		}

		.h3#{$infix} {
			@include h3;
		}

		.h4#{$infix} {
			@include h4;
		}

		.h5#{$infix} {
			@include h5;
		}

		.h6#{$infix} {
			@include h6;
		}

		.b-fsbodylarge#{$infix}{
			@include fsbodylarge;
		}

		.b-fsbodyregular#{$infix}{
			@include fzlh(15px, 24px);
		}

		.small#{$infix}{
			@include fssmall;
		}
	}
}

.b-fsbtn{ @include fsbtn; }


blockquote{
	@include fsblockquote;
	quotes: "�" "�" "�" "�";
	padding-top: 62px;
	position: relative;
	color: #505050;
	@include mq(md){
		padding-left: 18px;
	}

	&:before{
		@include icon(quote);
		color: rgba(#000, .2);
		font-size: 40px;
		max-height: 40px;
		line-height: 1;
		position: absolute;
		top: 0;
		left: 0;
	}
}

p{
	margin-bottom: 30px;
	line-height: 28px;
	&:empty{
		display: none;
	}
}

.lead{
	@include fslead;
	@include fzlh(30px, 48px);
	color:$black; 
	&.is-small{
		@include fzlh(22px, 32px);
	}
	
	@include mq-down(sm){
		@include fzlh(30px, 48px);
	}
	@include mq-down(xs){
		@include fzlh(22px, 32px);
	}
}

small, .small{
	@include fssmall;
}
