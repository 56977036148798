/*crit:start*/
.c-header{
	border-bottom: 10px solid $yellow;
	background-color: #fff;
	top:0;
	.navbar{
		padding: 12px 0;
		margin-left: -6px;
		margin-right: -6px;
		&-brand{
			margin-right: 0;
		}
		&-center{
			img{
				height: 28px;
			}
			@include mq-down(sm){
				img{
					height: 24px;
				}
			}
			@include mq-down(xs){
				display: none;
			}
		}
		&-right{
			.o-btn--icon{
				width: 36px;
				height: 36px;
				padding:0;
				font-size: 26px;
			}
		}
		@include mq-between(sm,md){
			padding: 20px 0;
			margin-left: 0;
			margin-right: 3px;
			&-brand{
				img{max-width: 140px}
			}
			&-center{
				img{max-width: 299px}
			}
		}
		@include mq-down(xs){
			padding: 10px 0;
			&-brand{
				img{max-width: 124px}
			}
			&-right{
				margin-top: -4px;
				.js-searchtoggle{
					font-size: 22px;
				}
				.js-mobilemenutoggle{
					font-size: 19px;
					margin-right: -5px;
				}

			}
		}
	}
	@include mq-down(xs){
		border-bottom: 6px solid $yellow;
		padding-left: 6px;
		padding-right: 8px;
	}
	&--bt{
		padding-left: 24px;
		padding-right: 24px;
		background-color: #fff;
		border-bottom:1px solid $border;
		padding-top: 66px;
		padding-bottom: 66px;
		/*crit*/
		transition: .3s ease-out;
		@include mq-down(xs){
			padding-top: 20px;
			padding-bottom: 20px;
			margin-top:100px;
			// img{
			// 	height: 25px;
			// }
		}
	}
}
/*crit:end*/
