//@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// used by generic
$fonts: (
	(name: 'Sommet', path: '/www_shared/assets/fonts/Sommet', weight: 400, style: normal),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Book Oblique', weight: 400, style: oblique),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Heavy', weight: 900, style: normal),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Heavy Oblique', weight: 900, style: oblique),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Light', weight: 300, style: normal),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Light Oblique', weight: 300, style: oblique),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Medium', weight: 500, style: normal),
// 	(name: 'Avenir', path: '/www_shared/assets/fonts/Avenir Medium Oblique', weight: 500, style: oblique),
// 	(name: 'Avenir-Black', path: '/www_shared/assets/fonts/Avenir Black', weight: 400, style: normal),
// 	(name: 'Avenir-Black', path: '/www_shared/assets/fonts/Avenir Black Oblique', weight: 400, style: oblique),
// 	(name: 'Avenir-Roman', path: '/www_shared/assets/fonts/Avenir Roman', weight: 400, style: normal),
// 	(name: 'Avenir-Roman', path: '/www_shared/assets/fonts/Avenir Roman Oblique', weight: 400, style: oblique)
);
