.c-module{
	$_this: &;

	&__head{
		#{$_this}__title{
			//border-bottom: 4px solid $black;
			color:$black;
			padding-bottom: 15px;
			padding-top: 45px;
			/* crit */
			&.is-border{
				border-bottom: 2px solid $dark;
			}

			@include mq-down(sm){
				padding-bottom: 30px;
				padding-top: 30px;
			}
			@include mq-down(xs){
				padding-bottom: 25px;
				padding-top: 25px;
			}

			&-small{
				@include fzlh(13px, 20px);
				letter-spacing: 3px;
				text-transform: uppercase;
				font-family: 'Arial', Helvetica, sans-serif;
				border-top:2px solid $dark;
				border-bottom:1px solid $gray-200;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 24px;
				@include mq-down(xs){
					margin-bottom: 10px;
				}
			}

		}

	}

// c-module--tabs
	&--tabs{

		.o-tabs{
			&__nav {
				&-link {
					padding:0;
					font-size: 25px;
					font-weight: bold;
					margin-right: 40px;
					/*crit*/
					transition: .3s;
					&:last-child{
						margin-right: 0;
					}
					&.active {
						background-color: transparent;
						font-size: 31px;
						span{
							border-bottom: 3px solid $black;
						}
					}
				}
			}
			 
		}

	}

// c-module--filter
	&--filter{
		.row{
			margin-left: -24px;
			margin-right: -24px;
		}
		.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto{
			padding-left: 24px;
			padding-right: 24px;
		}
		.js-filterbtn{
			padding-left: 20px;
			border-width: 1px;
			/* crit */
			&:hover,
			&:focus{
				color: $black;
			}
			&.is-open{
				position: relative;
				background-color: $gray-100;
				&:after{
					display: block;
					width:calc(100% + 2px);
					background-color: #F6F6F6;
					border-left:1px solid #bababa;
					border-right:1px solid #bababa;
					content: "";
					height: 30px;
					position: absolute;
					left:-1px;
					bottom: -26px;
					z-index: 4;
				}
			}
		}
		.o-btn--outline,
		select{
			border-color: #bababa;
			/* crit */
		}
		select{
			background-color: #F6F6F6;

		}
		input[type=search]{
			padding-left: 50px;
			border-color: #bababa;
			outline:none;
			/* crit */
			&:focus{
				border:1px solid #2E2E2E;
			}

		}
		&-btn{
			position: absolute;
			left:10px;
			top:8px;
			/* crit */
		}
		&-options{
			background-color: #F6F6F6;
			border:1px solid #bababa;
			padding: 24px;
			margin-top: 24px;
			.row{
				margin-left: -12px;
				margin-right: -12px;
			}
			.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto{
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}
// c-module--filter
	&--filter-mobile{
		@include mq(sm-down){
			// TODO: for irawan - this is temporary added since it's causing a lot of issue when going to live
			// Need to fix this after
			/* crit:start */
			//display: none;
			/* crit:end */
		}

		.o-btn--outline,
		select{
			border-color: #bababa;
			/* crit */
		}
		select{
			border-color: #bababa;
			/* crit */
		}
		select{
			background-color: #F6F6F6;
		}
		input[type=search]{
			padding-left: 50px;
			border-color: #bababa;
			/* crit */
		}
		.js-filterbtn{
			background-color: #fff;
			border-left: 0;
			border-right: 0;
			border-bottom: 0;
			text-align: center;
			/*crit*/
			z-index: 2;
			span{
				float: left;
				margin-top: 5px;
				margin-right: 0;
				/*crit*/
			}
			&:focus{
				color: $black;
			}
		}
		.js-filter-dropdownmob{
			&.is-active{
				.c-module--filter-options{
					opacity: 1;
					border:0;
					z-index: 1;
					pointer-events: auto;
				}
			}
		}
		.c-module--filter-options{
			height: 100vh;
		    position: fixed;
		    margin: 0;
		    top: 0;
		    width: 100%;
		    overflow-y: auto;
		    /*crit*/
			transition: .3s;
			opacity: 0;
			pointer-events: none;
		}
		.o-filter-dropdown{
			z-index: inherit;

			&-menu{
				position: relative;
				max-height: none;
			}
			&-options{
				background-color: #F6F6F6;
				border:1px solid #bababa;
				padding: 24px;
				margin-top: 24px;
				.row{
					margin-left: -12px;
					margin-right: -12px;
				}
				.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto{
					padding-left: 12px;
					padding-right: 12px;
				}
			}
		}

	}

	// c-module--hero{
	&--hero{
		&-mp4{
			max-height: 596px;
		}
		&-bg{
			padding-bottom: percentage(596/1320);
			/*crit*/
		}
		@include mq-down(md){
			.container{
				max-width: 100%;
				.row{
					margin-left: -30px;
					margin-right: -30px;
				}
				.col{
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	// c-module--ads
	&--ads{
		@include mq-down(md){
			.container{
				max-width: 100%;

				.col{
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	// c-module--comments
	&--comments{
		&-body{
			.js-expandcomment{
				.o-collapse__icon{
					right: 20px;
					/*crit*/
				}
			}
		}
	}


	// c-module--comments
	&--related{
		.o-card{
			&--news{
				.o-card__media{
					padding-bottom: percentage(188/285);
				}
			}
			&__icon{
				width: 40px;
				height: 40px;
				left: 8px;
				top:8px;
				font-size: 25px;
				@include mq-down(xs){
					left:0;
					top:16px;
					width: 36px;
					height: 36px;
					font-size: 20px;
				}
			}
		}
		@include mq-down(xs){
			h2.u-fz32{
				@include fzlh(26px, 36px);
			}
		}
	}

	&--engage{
		&.is-bd50{
			border-color:rgba($white, 0.2);
			.o-cols{
				border-color:rgba($white, 0.2);
			}
		}
		@include mq-between(sm, md){
			.o-cols{
				border-right:1px solid rgba($white, 0.2);
				border-bottom: 0;
				&:last-child{
					border: 0;
				}
			}

		}
		@include mq-down(xs){
			.o-cols{
				border-bottom:1px solid rgba($white, 0.2);
				&:last-child{
					border-bottom: 0;
				}
			}
		}
	}

	// c-module--trendslanding
	&--trendslanding{
		.o-richtext{
			> *:last-child:not(.lead){
				margin-bottom: 0;
				padding-bottom: 0;
			}
			.lead,
			p.lead{
				@include fzlh(30px, 48px);
			}
			p{
				@include fzlh(22px, 32px);
			}
			hr{
				border-color: rgba($white, 0.15);
			}
			blockquote{
				color: $white;
				@include fzlh(22px, 32px);
				margin-top: 50px;
				.u-fsi, small{
					color:rgba($white, 0.5);
					@include fzlh(16px, 28px);
					font-style: italic;
				}
			}

			@include mq-down(xs){
				.lead,
				p.lead{
					@include fzlh(22px, 32px);
				}
				p{
					@include fzlh(16px, 28px);
				}
			}



		}
		.o-cols__3cols .o-cols{border-color: rgba($white, 0.15);}

		&.is{
			@each $trend, $hex in $trends{
				&-#{$trend}{
					.o-card--tile .o-card__title-icon{
						background-color: $hex;
					}
					.o-richtext blockquote{
						border-color: $hex;
						&:before{
							background-color: $hex;
						}

					}
				}
			}
		}

		@include mq-down(md){
			.o-cols__3cols{
				.o-cols{
					border-right: 0;
				}
			}
		}

	}

	// c-module--search
	&--search{

		&-result{
			padding-top: 12px;
			align-items: center;
		}

	}


	&.is{
		&-theme{
			&-dark{
				background-color: $dark;
				color:$white;
					p{
							color:$white;
						}
				.o-richtext{
					a{
							color: $white;
							text-decoration: underline;
							&:hover{
								text-decoration: none;
							}
						}
				}

				.lead{
					color:$white;
				}

				.c-module__head .c-module__title-small{
					border-top-color: $white;
					border-bottom-color: rgba($white, 0.15);
				}

				.o-card{
					&__title{
						a{
							color: $white
						}
					}
					&__meta-title{
						color: $skyblue;
					}
					&--news{
						.o-card__title{
							color: $white
						}
					}
					&__body{
						p{
							color:$white;
						}
					}
				}
				.o-cols{
					border-color: rgba($white, 0.15);
					&__wrap:after{
						background-color: $dark;
					}
				}

				.o-cols__wrap .o-card--news{
					border-color: rgba($white, 0.15);
				}
			}
		}
	}

	// &--trends{
	// 	.col-lg-6.o-cols{
	// 		//min-height: 962px;
	// 	}
	// }

	&--loader{
		height: 100vh;
		transition: height .5s;
		h1{
		  cursor: default;
		  top: 160px;
		  left: 0;
		  right: 0;
		  bottom: 0;
		  width: 100%;
		  height: 100px;
		  margin: auto;
		  display: block;
		  text-align: center;
		  /*crit*/

		  span{
		  	  position: relative;
			  top: 20px;
			  display: inline-block;
			  font-weight: 400;
			  font-size: 60px;
			  /*crit*/
			  @include mq-down(sm){
			  	font-size: 48px;
			  	/*crit*/
			  }
			  @include mq-down(xs){
			  	font-size: 25px;
			  	/*crit*/
			  }
		  	}
		}
		&.is-finish{
			height: 180px;

			@include mq-down(xs){
			  	height: 100px;
			}
		}
	}


}


