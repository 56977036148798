.o-collapse {
	$_this: &; // {{ .o-collapse }}
	$collapselist-toggle-size: 14px;
	$collapselist-toggle-thickness: 2px;
 

	&__icon {
		width: $collapselist-toggle-size;
		height: 24px;
		/* crit */
		&-x {
			height: $collapselist-toggle-thickness;
			/* crit */
		}
		&-y {
			width: $collapselist-toggle-thickness;
			height: $collapselist-toggle-size;
			transform: translate(-50%, -50%) scaleY(0);
			/* crit */
			transition: transform .3s;
			.collapsed & {
				transform: translate(-50%, -50%) scaleY(1);
				/* crit */
			}
		}
	}

	&__content{
		padding: 20px 0;
	}
}
