
.c-search{
	height: 100%;
	border-bottom: 10px solid $yellow;

	// {{ .c-search__box }}
	&__box{
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&-wrap{
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center; 
		}
	}

	// {{ .c-search__input }}
	&__input[type=search]{
		border:0;
		font-size: 22px;
		line-height: 1; 
		color: $black;
		outline: 0;
		height: 50px;
		margin-top: 1px;
		padding-left: 10px;
		//padding: ((80px-24px)/2) 48px ((80px-24px)/2) 0; // (height of search bar - font-size) /2
		max-height: 100%;
		&::placeholder{
			color: rgba(35, 31, 32, 0.6);
		}
		&:focus{
			
		}
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
		  display: none;
		}
	}

	// {{ .c-search__clearbtn }}
	&__clearbtn{
		color: $black;
		right: 0;
		font-size: 33px;
		line-height: 1;
		height: 30px;
		outline: 0;
	}

	// {{ .c-search__btn }}
	&__btn{ 
		font-size: 28px;
		padding:2px 10px;
		&:hover,
		&:focus{
			background-color: $primary;
			border-color:$primary;
			color:$white;
		} 
	}

	&__filter{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		&-text{
			p{
				margin-bottom: 0;
			}
		}
		&-options{
			.o-btn{
				margin-right: 0
			}
		}
		@include mq-down(xs){
			&-text,
			&-options{
				width: 100%;
			}
			&-options{
				margin-top: 20px;
				.js-filter-sort{
					flex-direction: row;
					flex-wrap: wrap;
					.u-vam{
						width: 100%
					} 
				} 
			} 
		}

	}

	&__result{
		&-item{
			border-bottom: 2px solid $border;
			&:last-of-type{
				border-bottom: 0;
			}
		}
	}
	&__noresult{
		.u-bd{
			margin-bottom: -2px;
			border-width: 2px;
			border-color: $gray-150;
		}
		@include mq-down(xs){
			margin-left: -20px;
			margin-right: -20px;
		}

	}

	@include mq-down(xs){
		padding-left: 24px;
		padding-right: 24px;
		border-width: 6px;
		&__box{
			padding-top: 10px;
			padding-bottom: 10px;
		}
		&__btn{
			font-size: 19px;
		    padding: 0;
		    width: 24px;
		    height: 24px;
		    margin-top: -7px;
		}
		&__clearbtn{
			width: 24px;
		    height: 24px;	
		    font-size: 22px;
		}
		&__input[type=search]{
			@include fzlh(16px,28px);
		}
	}
}

