.o-well{
	$_this: &;
	margin-left: -15px;
	margin-right: -15px;
	padding-top: 20px; 
	transition: .3s;
	//opacity: 0;

	//&.is-load{opacity: 1;}

	&--item{
		border-bottom: 1px solid $border;
		padding-bottom: 16px;
		&-input{
			label{
				margin-bottom: 0;
			}
		}
	}

	&--wrap{

		.o-well{
			border-bottom: 1px solid $border;
			padding-bottom: 16px;
			&:last-child{
				border:0;
			}
		}
	}
	&--more{
		font-size: 13px;
		font-style: italic;
		color: $black;
		display: inline-block;
		.sless,
		.smore{
			position: relative;
			display: inline-block;
			padding-left: 15px;
			&:before{
				content: "";
				width: 10px;
				background-color: $black;
				height: 2px;
				display: inline-block;
				@include vertical-center;
				left:0;
			}
			&:after{
				content: "";
				height: 10px;
				background-color: $black;
				width: 2px;
				display: inline-block;
				@include vertical-center;
				left:4px;
			}
		}
		.sless{
			display: block;
			&:after{ display: none; }
		}
		.smore{
			display: none;
		}
		&.collapsed{
			.sless{
				display: none;
			}
			.smore{
				display: block;
			}
		}
	}
	.daterange{
		.no-gutters .col{margin-right: -1px;}
		select{
			color:$black;
			font-size: 15px;
		}
		.o-btn--black{
			background-color: $gray-200;
			color: $black;
			border-color:$gray-200;
		}
	}

}