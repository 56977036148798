.o-gallery {
	$_thumbWidth: 120px;
	$_thumbHeight: 88px;
	$_sliderPadding: 16px;
	$_bgColor: $dark;

	background-color: $_bgColor;
	z-index: 1;
	/* crit */ 

	&.is-init{
	}

	&:not(.rsFullscreen){
		.o-gallery__img{
			//margin: 0 !important;
		}
	}

	&.rsFullscreen{
		.rsFullscreenBtn{
			background-color: darken($_bgColor, 10%);
			right: 20px;
			border-radius:0px;
		}
		.rsFullscreenIcn{
			background-position: center center;
			background-image: url('../img/close.svg');
		}
		.o-gallery__media{
			height: calc(100vh - 70px);
		}
		.o-gallery__text{
			margin:0 !important;
		}
	}
	&__img{
		&.rsImg{ 
		}
	}

	&__item {
		padding: $_sliderPadding 32px $_sliderPadding $_sliderPadding;
		/* crit */ 
	}
	// {{ .o-gallery__media }}
	&__media { 
		position: relative;
		overflow:hidden;
		height: 484px; 
		/* crit */ 
		// @include mq(sm-md){
		// 	height: 340px; 
		// }
		@include mq-down(md){
			height: 320px; 
		}
		@include mq-down(xs){
			height: 250px; 
		}

		img{
			position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            height: 100%;
            width: auto;
            border: 0;
            margin:0 !important;

		}
	}
	&__text {
		font-size: 12px;
		font-weight: 900;
		padding-right: 50px;
		color: #fff;
		position: absolute;
	    bottom: 18px;
	    display: inline-block;
		/* crit */ 
	}
	.o-richtext{ 
		color: #fff;
		display: inline-block;
		/* crit */ 
		p{
			margin-bottom: 0;
			/* crit */ 
		}
	}

	&__counter{
		position: absolute;
		//top: 13px;
		bottom: 18px;
		right: 13px;
		//margin-top: percentage(589/1088);
		font-size: 12px;
		z-index: 10; //prevent transition
		/* crit */ 

		.rsFullscreen &{
			//margin-top: 0;
			//top: 13px + 42px + 24px; //margin + size of the close btn + top of the close btn;
			border-radius: 20px;
			background-color: $_bgColor;
			padding: 5px 12px;
			/* crit */ 
		}
	}

	.rsOverflow,
	.rsSlide,
	.rsThumbs,
	.rsVideoFrameHolder {
		background-color: $_bgColor;
		/* crit */ 
	}
	.rsThumb {
		width: 120px;
		height: 80px;
		/* crit */ 
		img{
			width: auto;
			max-width:none;
			position: absolute;
		    left: 50%;
		    transform: translateX(-50%);
		    /* crit */ 
		}
		@include mq(lg){
			width: $_thumbWidth;
			height: $_thumbHeight;
			/* crit */ 
		}
		&.rsNavSelected .thumbIco {
			border-width: 4px;
			/* crit */ 
		} 
	}


	.rsFullscreen{
		&Btn{
			border-radius: 0;
			width: 36px;
			height: 36px;
			background-color: $_bgColor;
			right: 42px;
			top: 24px;
			/* crit */ 
		}
		&Icn{
			background: url('../img/icon-expand.svg') no-repeat;
			background-position: center center;
			background-size: 18px;
			width: 100%;
			height: 100%;
			margin: 0;
			opacity: 1;
			border-radius: 0;
			/* crit */ 
		}
	}
	.rsThumbs {
		&Ver {
			right: 16px;
			width: 120px;
			//padding-left: $_sliderPadding;
			height: auto;
			top: $_sliderPadding; //4px spacing/gap
			bottom: $_sliderPadding;
			/* crit */ 

			@include mq(0, 800px){
				//width: 120px + $_sliderPadding + $_sliderPadding;
			}
			@include mq(lg){
				//width: $_thumbWidth + $_sliderPadding + $_sliderPadding;
				
			}
		}

		&ArrowLeft {
			.rsThumbsArrowIcn {
				background-image: url('../img/chevron-up-white.svg');
				/* crit */ 
			}
		}

		&ArrowRight {
			.rsThumbsArrowIcn {
				background-image: url('../img/chevron-down-white.svg');
				/* crit */ 
			}
		}

		&ArrowIcn{
			width: 18px;
			height: 18px;
			margin-left: -9px;
			margin-top: -9px;
			background-repeat: no-repeat;
			background-size: 100%;
			/* crit */ 
		}
	}
	&.rsWithThumbsVer {
		.rsThumbsArrow {
			@include horizontal-center;
			width: 32px;
			background-color: $_bgColor;
			/* crit */ 

			&Right .rsThumbsArrowIcn{
				background-position: center 100%;
				/* crit */ 
			}
			&Left .rsThumbsArrowIcn {
				background-position: center 0;
				/* crit */ 
			}
		}
	}
}
