//@import "../../node_modules/bootstrap-select/sass/variables"
//@import "../../node_modules/bootstrap-select/sass/boostrap-select"

$color-red-error: rgb(185, 74, 72) !default;
$color-green-success: #28a745;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;

$width-default: 220px !default; // 3 960px-grid columns

$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)

//** Placeholder text color
$input-color-placeholder: #999 !default;
$input-alt-color-placeholder: rgba(255, 255, 255, 0.5) !default;

$input-padding-y-sm: .25rem !default;
$input-padding-x-sm: .5rem !default;

$input-padding-y-lg: 0.5rem !default;
$input-padding-x-lg: 1rem !default;



// Mixins
@mixin cursor-disabled() {
  cursor: not-allowed;
}

@mixin box-sizing($fmt) {
  -webkit-box-sizing: $fmt;
     -moz-box-sizing: $fmt;
          box-sizing: $fmt;
}

@mixin box-shadow($fmt) {
  -webkit-box-shadow: $fmt;
          box-shadow: $fmt;
}

@function fade($color, $amnt) {
  @if $amnt > 1 {
    $amnt: $amnt / 100; // convert to percentage if int
  }
  @return rgba($color, $amnt);
}

// Rules
/* crit:start */
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0; /*IE9 and below*/
  vertical-align: middle;
  
  &.ais-sort-by-selector{
    width: 100%;
  }

  // The selectpicker button
  > .dropdown-toggle {
    position: relative;
    width: 100%;
    // necessary for proper positioning of caret in Bootstrap 4 (pushes caret to the right)
    text-align: right;
    white-space: nowrap;
    // force caret to be vertically centered for Bootstrap 4 multi-line buttons
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      margin-top: -1px;
    }

    &.bs-placeholder {
      &,
      &:hover,
      &:focus,
      &:active {
        color: $input-color-placeholder;
      }

      &.btn-primary,
      &.btn-secondary,
      &.btn-success,
      &.btn-danger,
      &.btn-info,
      &.btn-dark {
        &,
        &:hover,
        &:focus,
        &:active {
          color: $input-alt-color-placeholder;
        }
      }
    }
    &:hover,
    &:focus{
      color: $dark;
      border-color: $dark;
      outline: none;
    }
  }

  > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;

    &.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2 !important;
    }
  }

  // Error display
  .has-error & .dropdown-toggle,
  .error & .dropdown-toggle,
  &.is-invalid .dropdown-toggle,
  .was-validated & .selectpicker:invalid + .dropdown-toggle {
    border-color: $color-red-error;
  }

  &.is-valid .dropdown-toggle,
  .was-validated & .selectpicker:valid + .dropdown-toggle {
    border-color: $color-green-success;
  }

  &.fit-width {
    width: auto !important;
  }

  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: $width-default;
  }

  > select.mobile-device:focus + .dropdown-toggle,
  .dropdown-toggle:focus {
    //outline: thin dotted #333333 !important;
    //outline: 5px auto -webkit-focus-ring-color !important;
    //outline-offset: -2px;
    outline: none;
    border-color: $dark;
    box-shadow: none;
  }

  &.show{
    .dropdown-toggle{
      background-color: #fff;
    }
  }


}
/* crit:end */


// The selectpicker components
.bootstrap-select {
  &.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
    height: auto;

    :not(.input-group) > &:not([class*="col-"]) {
      width: 100%;
    }

    &.input-group-btn {
      float: none;
      z-index: auto;
    }
  }

  .form-inline &,
  .form-inline &.form-control:not([class*="col-"]) {
    width: auto;
  }

  &:not(.input-group-btn),
  &[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
  }

  // Forces the pull to the right, if necessary
  &,
  &[class*="col-"],
  .row &[class*="col-"] {
    &.dropdown-menu-right {
      float: right;
    }
  }

  .form-inline &,
  .form-horizontal &,
  .form-group & {
    margin-bottom: 0;
  }

  .form-group-lg &.form-control,
  .form-group-sm &.form-control {
    padding: 0;

    .dropdown-toggle {
      height: 100%;
      font-size: inherit;
      line-height: inherit;
      border-radius: inherit;
    }
  }

  &.form-control-sm .dropdown-toggle,
  &.form-control-lg .dropdown-toggle {
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
  }

  &.form-control-sm .dropdown-toggle {
    padding: $input-padding-y-sm $input-padding-x-sm;
  }

  &.form-control-lg .dropdown-toggle {
    padding: $input-padding-y-lg $input-padding-x-lg;
  }

  // Set the width of the live search (and any other form control within an inline form)
  // see https://github.com/silviomoreto/bootstrap-select/issues/685
  .form-inline & .form-control {
    width: 100%;
  }

  &.disabled,
  > .disabled {
    @include cursor-disabled();

    &:focus {
      outline: none !important;
    }
  }

  &.bs-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0 !important;
    padding: 0 !important;

    .dropdown-menu {
      z-index: $zindex-select-dropdown;
    }
  }

  // The selectpicker button
  .dropdown-toggle {
    border-radius: 0;
    background-color: $gray-100;
    color:$dark;
    border-color: #bababa;
    .filter-option {
      position: static;
      top: 0;
      left: 0;
      float: left;
      height: 100%;
      width: 100%;
      text-align: left;
      overflow: hidden;
      flex: 0 1 auto; // for IE10

      @at-root .bs3#{&} {
        padding-right: inherit;
      }

      @at-root .input-group .bs3-has-addon#{&} {
        position: absolute;
        padding-top: inherit;
        padding-bottom: inherit;
        padding-left: inherit;
        float: none;

        .filter-option-inner {
          padding-right: inherit;
        }
      }
    }

    .filter-option-inner-inner {
      overflow: hidden;
    }

    // used to expand the height of the button when inside an input group
    .filter-expand {
      width: 0 !important;
      float: left;
      opacity: 0 !important;
      overflow: hidden;
    }

    .caret {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  .input-group &.form-control .dropdown-toggle {
    border-radius: inherit;
  }

  &[class*="col-"] .dropdown-toggle {
    width: 100%;
  }

  // The selectpicker dropdown
  .dropdown-menu {
    min-width: 100%;
    background-color: #fff;
    border-radius: 0;
    border:1px solid #bababa;
    box-shadow: none;
    margin-top: -3px;
    border-top-color: white;
    @include box-sizing(border-box);

    > .inner:focus {
      outline: none !important;
    }

    &.inner {
      position: static;
      float: none;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    }

    li {
      position: relative;

      &.active small {
        color: $input-alt-color-placeholder !important;
      }

      &.disabled a {
        @include cursor-disabled();
      }

      a {
        cursor: pointer;
        user-select: none;
        border-radius: 0;
        padding:8px 18px;

        &.opt {
          position: relative;
          padding-left: 2.25em;
        }

        span.check-mark {
          display: none;
        }

        span.text {
          display: inline-block;
        }
        &.active{
          background-color: $primary;
        }
      }

      small {
        padding-left: 0.5em;
      }
    }

    // .notify {
    //   position: absolute;
    //   bottom: 5px;
    //   width: 96%;
    //   margin: 0 2%;
    //   min-height: 26px;
    //   padding: 3px 5px;
    //   background: rgb(245, 245, 245);
    //   border: 1px solid rgb(227, 227, 227);
    //   @include box-shadow(inset 0 1px 1px fade(rgb(0, 0, 0), 5));
    //   pointer-events: none;
    //   opacity: 0.9;
    //   @include box-sizing(border-box);
    // }
  }

  // .no-results {
  //   padding: 3px;
  //   background: #f5f5f5;
  //   margin: 0 5px;
  //   white-space: nowrap;
  // }

  // &.fit-width .dropdown-toggle {
  //   .filter-option {
  //     position: static;
  //     display: inline;
  //     padding: 0;
  //   }

  //   .filter-option-inner,
  //   .filter-option-inner-inner {
  //     display: inline;
  //   }

  //   .bs-caret:before {
  //     content: '\00a0';
  //   }

  //   .caret {
  //     position: static;
  //     top: auto;
  //     margin-top: -1px;
  //   }
  // }

  // &.show-tick .dropdown-menu {
  //   .selected span.check-mark {
  //     position: absolute;
  //     display: inline-block;
  //     right: 15px;
  //     top: 5px;
  //   }

  //   li a span.text {
  //     margin-right: 34px;
  //   }
  // }

  // default check mark for use without an icon font
  // .bs-ok-default:after {
  //   content: '';
  //   display: block;
  //   width: 0.5em;
  //   height: 1em;
  //   border-style: solid;
  //   border-width: 0 0.26em 0.26em 0;
  //   transform: rotate(45deg);
  // }

   &.show .dropdown-toggle{
    &:hover{
      border-color: #bababa;
    }
  }


}

.bootstrap-select.show-menu-arrow {
  &.open > .dropdown-toggle,
  &.show > .dropdown-toggle {
    z-index: ($zindex-select-dropdown + 1);
  }

  .dropdown-toggle .filter-option {
    &:before {
      content: '';
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $color-grey-arrow;
      position: absolute;
      bottom: -4px;
      left: 9px;
      display: none;
    }

    &:after {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      position: absolute;
      bottom: -4px;
      left: 10px;
      display: none;
    }
  }

  &.dropup .dropdown-toggle .filter-option {
    &:before {
      bottom: auto;
      top: -4px;
      border-top: 7px solid $color-grey-arrow;
      border-bottom: 0;
    }

    &:after {
      bottom: auto;
      top: -4px;
      border-top: 6px solid white;
      border-bottom: 0;
    }
  }

  &.pull-right .dropdown-toggle .filter-option {
    &:before {
      right: 12px;
      left: auto;
    }

    &:after {
      right: 13px;
      left: auto;
    }
  }

  &.open > .dropdown-toggle .filter-option,
  &.show > .dropdown-toggle .filter-option {
    &:before,
    &:after {
      display: block;
    }
  }

}

// .bs-searchbox,
// .bs-actionsbox,
// .bs-donebutton {
//   padding: 4px 8px;
// }

// .bs-actionsbox {
//   width: 100%;
//   @include box-sizing(border-box);

//   & .btn-group button {
//     width: 50%;
//   }
// }

// .bs-donebutton {
//   float: left;
//   width: 100%;
//   @include box-sizing(border-box);

//   & .btn-group button {
//     width: 100%;
//   }
// }

// .bs-searchbox {
//   & + .bs-actionsbox {
//     padding: 0 8px 4px;
//   }

//   & .form-control {
//     margin-bottom: 0;
//     width: 100%;
//     float: none;
//   }
// }




.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}
.bootstrap-select.btn-group.disabled,.bootstrap-select.btn-group>.disabled {
    cursor: not-allowed;
    opacity: .7;
}
.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}