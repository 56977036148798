.o-richtext{
	// > *:last-child:not(.lead):not(.b-lead):not(.o-card__tags){
	// 	margin-bottom: 0;
	// 	padding-bottom: 0;
	// }

	h1,h2,h3,h4,h5,h6{
		margin-bottom: 10px;
		&:not(:first-child){
			margin-top: 10px;
		}
	}
	h1{
		@include fzlh(60px, 72px);
		@include mq-down(sm){
			@include fzlh(52px, 64px);
		}
		@include mq-down(xs){
			@include fzlh(33px, 44px);
		}
	}

	h2{
		@include h5;
		font-weight: bold;
	}

	h3,h4,h5{
		@include h6;
		font-weight: bold;
	}


	img{
		margin: 0 auto 15px;
	}

	figure {
		margin-bottom: 30px;
	}

	.figure.pull-left {
		float: left;
		margin-right: 15px;
	}
	.figure.pull-right {
		float: right;
		margin-left: 15px;
	}
	.figure.fullwidth img {
		width: 100%;
	}
	.figure-caption {
		color: rgba(35,31,32,.65);
		font-style: italic;
		font-size: 13px;
		font-size: .8125rem;
	}
	&__img{
		margin-bottom: 30px;
		img{
			margin-bottom: 16px;
		}
	}

	ul,	ol {
		padding-left: 0;
		margin-bottom: 30px;
	}

	ul:not(.slick-dots) {
		list-style: none;
		li {
			position: relative;
			padding-left: 13px;
			&:before {
				position: absolute;
				top: 10px;
				left: 0;
				content: '';
				width: 3px;
				height: 3px;
				background-color: currentColor;
				border-radius: 50%;
				left: 0;
			}

			&[style*="list-style: none"]:before{
				content: none;
			}
		}
	}
	ol{
		list-style: none;
		counter-reset: bt-number;
		li{
			counter-increment: bt-number;
			margin-bottom: 8px;
			&:before{
				content: counter(bt-number);
				background-color: $primary;
				color: $white;
				border-radius: 40px;
				width: 28px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				display: inline-block;
				font-weight: 700;
				margin-right: 12px;
			}

		}
	}

	.lead{
		@include fzlh(22px,32px);
		margin-bottom: 40px;
	}
	blockquote{
		border-left: 6px solid $yellow;
		padding-left: 30px;
		padding-top: 0;
		margin-bottom: 30px;
		font-style: normal;
		position: relative;
		color: $dark; 
		&:before{
			content: "";
			width: 6px;
			height: 6px;
			background-color: $yellow;
			display: block;
			position: absolute;
			left:-6px;
			top:-10px;
		}
		p{
			margin-bottom: 0px;
		}
		.o-author{
			font-size: 16px;
			margin-top: 10px;
			color: rgba(35, 31, 32, 0.65);
		}
	}


}
