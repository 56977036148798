.c-nav-mobile{
    $_this: &; 
    /* crit */ 

    // {{ .c-nav-mobile__collapse }}
    &__collapse{
        font-size: 10px;
        color: $white;
        right: 0;
        top: 50%;
        width: 26px;
        outline: none;
        /* crit */
        transform: translateY(-50%); 
        &:focus,
        &:hover{
        	outline: none;
        }
        span:before{
        	content: icon('angle-up');
        }
        &.collapsed{
        	span:before{
        		content: icon('angle-down');
        	}
        }
    }
    &__item{
        padding: 0px;
        /* crit */
    }

    &__link{ 
        letter-spacing: 1px;
        padding: 13px 16px;
        border-top:1px solid $dark;
        border-bottom: 1px solid rgba($white, 0.1);

        /* crit */

        >a{
            color: $white;
            text-transform: capitalize;
            font-family: $sommet;
            @include fzlh(22px, 32px);
            letter-spacing: 1px;
            font-weight: 700;

            /* crit */
            &:hover,
            &:focus{
            	color: rgba($white, 0.5);
            }

        }
        &-small{ 
            padding: 0 16px;
            font-family: $sommet;
            margin-top: 15px;
            /* crit */

            >a{
                color: $white;
                text-transform: capitalize;
                @include fzlh(17px, 24px);
                /* crit */
                span{
                    margin-left: 10px;
                }
            }
        }
    }

    &__sub{ 
        background: $dark;
    	margin-top: -1px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($white, 0.1);
        /* crit */
        position: relative;
        z-index: 1;
    }

    &__sub-link{
        color: $white;
        text-transform: capitalize;
        font-size: 13px;
        padding: 5px 0;
        /* crit */
        &:hover,
        &:focus{
            color: rgba($white, 0.75);
        }
    }


    &__parent{ 
        .c-nav-mobile__child-sub{ 
            padding-bottom: 10px;
            /*crit*/
        }
    }

    &__child{
        padding: 0 16px;
        /* crit */
        >a{
            padding: 0;
            color: $white;
            text-transform: capitalize; 
            display: block;
            @include fzlh(16px, 32px);
            &:hover,
            &:focus{
            	color: rgba($white, 0.5);
            }
        } 
        .c-nav-mobile__collapse{
            .u-bgc-white{
                background-color: $gray-300;
            }
        }

        &-link{
            color: $gray-500;
            font-size: 14px;
            padding:10px 0 10px 24px;
            &:hover,
            &:focus{
                color: $gray-300;
            }
        }
    }
}
