.c-wrapper{
	//overflow: hidden;
	$_this: &;

	&__main{
		transition: .5s;
		position: relative;
		z-index: 21;
		width: 100%;
		background-color: #fff;
		/*crit*/
		@include mq(sm){
			padding-top:96px;
		}
		@include mq-down(xs){
			overflow: auto;
		}
	}
	&__offright{
		width: 400px;
		background-color: $dark;
		color:$white;
		padding: 12px 24px 35px 24px;
	    position: fixed;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    overflow-x: hidden;
	    overflow-y: auto;
	    color: #fff;
	    z-index: -1;
	    opacity: 0;
		/*crit*/
		transition: opacity .1s .5s;
	    @include mq-down(xs){
			width: 288px;

		}
	}
	&__offtop {
		/* crit:start */
		transform: translateY(-200px);
		-ms-transform: translateY(-200px);
		-webkit-transform: translateY(-200px);
		-moz-transform: translateY(-200px);
		position: fixed 0 0 auto;
		/* crit:end */
		overflow: auto;
		z-index: 1;
		//transition: transform .5s;
		background-color: #fff;
		//height: 78px;

		.is-search-active & {
			transform: none;
		}
	}
	&__overlay{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($dark, 0.5);
		opacity: 0;
		z-index: -1;
		.is-profilesidebar-active & {
			opacity: 1;
			z-index:22;
		}
		.is-profilesidebar-off & {
			transition: .3s;
			opacity: 0;
			//z-index: -1;
		}
	}

	&__searchoverlay{
		// transform: translateY(-120%);
		// -ms-transform: translateY(-120%);
		// -webkit-transform: translateY(-120%);
		// -moz-transform: translateY(-120%);

		background-color: $white;
	    position: fixed;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    overflow: auto;
	    width: 100%;
	    padding-top: 94px;

	    display: none;
	    /*crit*/

	    z-index: 30;
	    .is-search-active & {
			//transform: none;
			display: block;

		}

	}


	&.is{
		// when sidebar menu open
		&-menu-active{
			#{$_this}__offright{
				opacity: 1;
				transition: .1s;
			}
			#{$_this}__main{
				//transform: translateX(-400px);
				margin-left: -400px;
				width: 100%;
				@include mq-down(xs){
			    	//transform: translateX(-288px);
			    	margin-left: -288px;
			    }
			}
			.o-panel-fixbottom{
				margin-left: -400px;
				@include mq-down(xs){
			    	margin-left: -288px;
			    }
			}
			.is-fixscroll{
				position: static;
			}


		}
		&-pageloaded{
			#{$_this}__offright{
				z-index: 1;
			}
		}
	}
}

// JS
.js{
	&-mobilemenutoggle{
		&.is-active{
			.g-icon-hamburger:before{
				content: icon('close');
				font-size: 33px;
			}
		}
	}
}
