/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================
@media print {
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group; // h5bp.com/t
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	.collapse {
		height: auto !important;
		display: block;
	}
	[data-mh] {
		height: auto !important;
	} // Bootstrap specific changes end


	.c-wrapper__searchoverlay,
	.c-wrapper__offtop,
	.c-module--search,
	.c-footer,
	.navbar-right,
	.c-module--filter,
	.c-module--filter-mobile,
	.c-header--bt,
	.o-sideshare,
	.is-bt .c-header--bt{
		display: none !important;
	}
	.c-wrapper__main>.u-posa{
		position: relative !important;
		width: 100% !important;
	}
	.c-header{
		position: relative !important;
		width: 100% !important;
	}
	.c-header .navbar-brand{
		width: 50% !important;
	}
	.c-header .navbar-center {
		display: inline-block !important;
		text-align: right !important;
		width: 50% !important;
	} 

	.is-bt .navbar-center{
		transform: none !important;
	}





}
