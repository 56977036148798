/// Vertically centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/// Horizontally centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/// Makes an element visually hidden, but still accessible to keyboards and assistive devices.
/// @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility Hiding Content for Accessibility
@mixin element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

/// Reverses the CSS output created by the `element-invisible()` mixin.
@mixin element-invisible-off {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

/// Absolutely centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/// Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.
///
/// @param {Number} $triangle-size - Width of the triangle.
/// @param {Color} $triangle-color - Color of the triangle.
/// @param {Keyword} $triangle-direction - Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
@mixin css-triangle(
  $triangle-size,
  $triangle-color,
  $triangle-direction,
  $isPseudo: true,
) {
  display: block;
  width: 0;
  height: 0;

  border: inset $triangle-size;

  @if ($isPseudo){
    content: '';
  }

  @if ($triangle-direction == down) {
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $triangle-color transparent transparent;
  }
  @if ($triangle-direction == up) {
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent $triangle-color;
  }
  @if ($triangle-direction == right) {
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent $triangle-color;
  }
  @if ($triangle-direction == left) {
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent $triangle-color transparent transparent;
  }
}