.o-gotop {
	width: 48px;
	height: 48px;
	transition: .3s;
	color: #fff;
	background-color: transparent;
	border:1px solid rgba($white, 0.15);
	right: 30px;
	@include mq-down(xs){
		top:10px;
	}
	&:hover,
	&:focus {
		background-color: transparent;
		color: #fff;
		border:1px solid $white;
	}
	span{
		font-size: 10px;
	}
}
