.c-boards{
	&__title{}
	&__detail{
		&-close{
			color: $text;
			width: 52px;
			height: 52px;
			transition: .3s;
			padding-top: 2px;
			font-size: 24px;
			&:hover{
				background-color: $dark;
				color:$white;
			}
		}
		&-title{
			padding-right: 40px;
			color:$blue;
			@include fzlh(32px, 40px);
			@include mq-down(xs){
				@include fzlh(26px, 36px);
			}
		}
		&-subtitle{
			@include fzlh(22px, 32px);
			color: $text;
			@include mq-down(xs){
				@include fzlh(16px, 28px);
			}
		}
		&-inner{
			background-color: $gray-100;
			.o-cols__wrap:after{
				display: none;
			}
			@include mq-down(md){
				padding-top: 15px;
				.o-cols__wrap .o-cols{
					border:0;
				}
			}
		}
		&-contact{ 
			span{
				font-size: 18px;
				color:#BABABA;
				display: inline-block;
			    width: 50px;
			    text-align: center;
			    vertical-align: middle;
			}
			li{
				padding: 5px 0;
			}
			@include mq-between(sm,md){
				li{
					display: inline-block;
					border-right:1px solid #DEDEDE;
					padding-right: 20px;
					padding-left: 20px;
					&:first-child{
						padding-left: 0px;
					}
					&:last-child{
						border-right: 0;
					}
					span{
						text-align: left;
						width: 40px;
					}
				}
			}
			@include mq-down(md){
				padding:5px 0;
				margin-bottom: 10px;
				
			}

			@include mq-down(xs){
				border-top:1px solid #DEDEDE;
				border-bottom:1px solid #DEDEDE;
				padding:12px 0;
				margin-bottom: 20px; 
			}
		}
	}
	@include mq-down(xs){ 
		&__col{
			margin-bottom: 16px;
		}
	}

	&.is-rowactive{
		.o-board:not(.active){
			opacity: 0.5;
		}
	}
	&.disabledClick{
		.c-boards__col{
			&:before{
				content: "";
				width: 100%;
				height: 100%;
				background-color: #fff;
				position: absolute;
				opacity: 0;
				top:0;
				left:0;
				z-index: 1;
				display: block;
			}
		}
	}
}