// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/";
$slick-font-family: "g-icons";
$slick-loader-path: "./";
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\2190";
$slick-next-character: "\2192";
$slick-dot-character: "\2022";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

/* crit:start */
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';
/* crit:end */

// ==========================================================================
// Override so that don't need to use assets from slick
// ==========================================================================

.slick{
	$_this: &;

	// {{ .slick-slider }}
	&-slider {
	    @include mq(md) {
	        user-select: initial;
	    }
	}

	// {{ .slick-slide }}
	&-slide {
	    height: auto;
	    flex: 1;
	    outline: 0;
	    /* crit */
	    .lt-ie10 {
	        height: 100%;
	        /* crit */
	    }
	}

	// {{ .slick-track }}
	&-track {
	    display: flex;
	    /* crit */
	    &:before {
	        content: none;
	        /* crit */
	    }
	    .lt-ie10 & {
	        &:before {
	            content: '';
	            /* crit */
	        }
	    }
	}

	// {{ .slick-list }}
	&-list{
		#{$_this}-loading &{
		    background: #fff 50% no-repeat;
		    /* crit */
		}
	}

	// {{ .slick-arrow }}
	&-arrow{
	}
	&-prev,
	&-next{
		z-index: 1;
		width: 35px;
		height: 35px;
		&:before{
			font-size: 40px;
			opacity: 1
		}
	}

	// {{ .slick-prev }}
	&-prev{
		left: 25px;
		&:before{
			@include iconfont;
  			content: icon('angle-left');
  			color: $white;
		}
	}

	// {{ .slick-next }}
	&-next{
		right: 25px;
		&:before{
			@include iconfont;
  			content: icon('angle-right');
  			color: $white;
		}
	}

	&-dots{
		//bottom:0px;
		li{
			margin:0;
			button{
				&:before{
					color:$dark;
					font-size: 30px;
				}
			}
			&.slick-active{
				button:before{
					color:$dark;
					opacity: 1;
				}
			}
		}
	}
}
