.o-tag{
	border:1px solid $black;
	display: inline-block;
	padding: 5px 15px;
	font-weight: 700;
	font-size: 13px;
	text-transform: capitalize;
	margin-right: 10px;
	/*crit*/
}
