.o-sideshare{
	position: relative;
	&--link{
		width: 60px;
		height: 60px;
		border:1px solid $border;
		color: $dark;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		background-color: #fff;
		/*crit*/
		cursor: pointer;
		&:hover,
		&:focus{
			text-decoration: none;
			background-color: $primary;
			color: $white;
			border-color: $primary;
			outline: none;
		}
	}
	&--item{
		margin-top: -1px;
		/*crit*/
	}
	&--list{ float: right }

	@include mq-down(md){
		&--itemshare{
			margin:12px auto;
			text-align: center;
			display: flex;
    		justify-content: center;
			/*crit*/
		}
		&--item{
			display: inline-block;
			border-right: 1px solid $border;
			/*crit*/
			&:last-child{
				border-right:0;
			}
		}
		&--link{
			border:0;
			height: 24px;
			/*crit*/
			&:hover,
			&:focus{
				background-color: #fff;
				color: $primary;
			}
		}
	}
}
