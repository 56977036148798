
.o-featured{
	$_this: &;
	border:1px solid $border;
	font-size: 13px;
	/* crit */ 


	&__head{
		border-bottom:1px solid $border;
		padding:10px;
		background-color: $gray-100;
		/* crit */ 
	}
	&__body{
		padding:13px;
		border-top:1px solid $border;
		margin-top: -1px;
		/* crit */ 
	}

	&__media{
		width: 80px;
		height: 80px;
		border-radius: 100px;
		margin:5px auto;
		/* crit */ 
	}
	&__name{
		color: $primary
	}
	&__department{
		color: $dark
	}

	&.is-col{
		.o-featured__body{
			width: 50%;
			border-right:1px solid $border;
			display: flex;
			align-items: center;
			flex-grow: 1;
    		max-width: 100%;
			&:last-child{
				border-right: 0;
			}
		}
		.o-featured__media{
			margin:0;
			width: 64px;
			height: 64px;
			flex: 0 0 64px;
    		max-width: 64px;
		}
		.o-featured__details{
			padding-left: 15px;
		}
		@include mq-down(xs){
			flex-direction: column;
			.o-featured__body{
				width: 100%;
				border-right:0;
			}
		}
	}

	&-profile{
		transition: .3s;
		&.is-show{
			opacity: 1;
		}
		.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto{
			padding-left: 0;
			padding-right: 0;
		}
		#{$_this} {
			&__title{
				@include fzlh(41px,52px);
				margin-bottom: 20px;
			}
			&__media{
				width: 200px;
				height: 200px;
				margin:0;
				@include mq-only(sm){
					width: 172px;
					height: 172px;
				}
				@include mq-only(xs){
					width: 127px;
					height: 127px;
					margin-bottom: 20px;
				}
			}
			&__role{
				@include fzlh(20px, 28px);
				font-family: $sommet;
				letter-spacing:  0.025em;
			}
			&__detail{
				margin-top: 20px;
				li{
					display: inline-block;
					border-right:1px solid #DEDEDE;
					padding-right: 20px;
					padding-left: 20px;
					&:first-child{
						padding-left: 0px;
					}
					&:last-child{
						border-right: 0;
					}
					span{
						text-align: left;
						width: 30px;
						color:#BABABA;
						vertical-align: middle;
					}
				}
				@include mq-only(xs){
					display: flex;
					border-top:1px solid #DEDEDE;
					border-bottom:1px solid #DEDEDE;
					flex-wrap: wrap;
					padding: 8px 0;
					li{
						flex: 0 0 50%;
    					max-width: 50%;
    					border-right: 0;
    					padding-left: 0;
    					margin-top: 5px;
    					margin-bottom: 5px;
					}
				}
			}
		}

		.o-card--small .o-card__head{
			flex: 0 0 110px;
    		max-width: 110px;
		}
		.o-cards{
			.o-cols:first-child{
				.o-card--small{
					border-top:0;
				}
			}
		}

	}


}
