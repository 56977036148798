// where rules with !important belong to
.u-bgc-lightgrey{
	.o-cols__wrap:after{
		background-color: #f6f6f6;
	}
}

[data-module='pagination']{
	visibility: hidden;
	opacity: 0;
	transition: .5s;
	/*crit*/
}
.is-pageloaded{
	[data-module='pagination']{
		// visibility: visible;
		// opacity: 1;
	}
	.c-main{
		opacity: 1;
		transition: .3s;
	}
}

.js-panel{
	margin-bottom: 120px;
	.row{
		@include mq(lg){
			border-left: 1px solid $border;
		}
	}
	@include mq-down(sm){
		margin-bottom: 80px;
	}
	@include mq-down(xs){
		margin-bottom: 40px;
	}
}
 

.is-posticky{
	position: sticky;
	top:120px;
	.o-card{
		z-index: 1;
	}
}
.is{
	&-homepage{
		.navbar-center{
			transition: .4s cubic-bezier(0.63, 0, 1, 0.99);
			transform: translateY(-100px);
			-ms-transform:translateY(-100px);
			-webkit-transform:translateY(-100px);
			-moz-transform:translateY(-100px);
			/*crit*/
		}
		.c-header--bt{
			display: block;
			
		}
		.c-main{
			padding-top: 0;
		}
	}
	&-bt{
		&-animated{
			.navbar-center{
				transform: none;
			}
			.c-module--loader{
				display: none;
			}
			.c-header--bt{
				//transform: translateY(-100%);
				// margin-top: -200px; // (margin top cause homepage o-card--news flickering)
				transform: translateY(-300px); //TODO for Irawan: please adjust the value if not right
				-ms-transform: translateY(-300px);
				-webkit-transform: translateY(-300px);
				-moz-transform: translateY(-300px);
			} 
		}
	}

}


.panel{
	will-change: transform;
	.o-card{
		will-change: transform
	}
}

[data-module='scrolltrends']{
	display: none;
	@include mq(lg){
		height:479px;
		overflow: hidden;
		display: block;
	}
}

[data-module="scrollmagic"],
[data-module="scrolltrends"]{
	left: auto !important;
}

.js-lazysizes{
	opacity: 0;
	/*crit*/
}
.is{
	&-lazysizes{
		&-loaded{
			opacity: 1;
		}
		&-loading{
			opacity: 0;
			/*crit*/
		}
	}
	&-fixscroll{
		position: fixed;
	    overflow-y: auto;
	    top: 96px;
	    bottom: 0;
	    padding-left: 35px;
	    padding-right: 35px;
	    /*crit*/
	    background-color: #fff;
	    z-index: 1;
	    @include mq-down(xs){
	    	padding-left: 24px;
	    	padding-right: 24px;
	    	top: 75px;
	    }
	    &--left{
	    	left:0;
	    	width: 16.6666666667%;
	    	background-color: #F6F6F6;
	    	border-right: 1px solid #DEDEDE;
	    	/*crit*/
	    }
	    &--right{
	    	right:0;
	    	width: 83.3333333333%;
	    	/*crit*/
	    	@include mq-down(md){
	    		width: 100%;
	    		padding-bottom: 100px;
	    	}
	    }
	}

	&-sticky{
		.headroom{
			position: fixed;
			top:0;
			width: 100%;
			z-index: 10;
			.navbar-center{
				img{
					transform: none;
				}
			}
		}
		.c-header--bt {
			position: fixed;
			top:71px;
			width: 100%;
			z-index: 10;
		}
		.c-main{
			padding-top: 110px;
		}
	}
 
}

 
