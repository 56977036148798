.c-off__sidebar{
	position: fixed;
	left: 0;
	top: 0;
	background-color: #fff;
	height: 100vh;
	padding: 48px;
	overflow-x: hidden;
	overflow-y: auto;
	//z-index: -1;
	transition: .3s;
	transition-delay: .2s;
	transform: translateX(-100%);
	/* crit */

	@include mq-only(lg){
		width: 900px;
	}
	@include mq-only(xs){
		padding: 24px;
	}

	&-close{
		right: 20px;
		top: 20px;
		font-size: 28px;
		color: $dark;
		z-index: 10;
	} 

	.is-profilesidebar-active & {
		z-index: 23; 
		transform: none;
	}
	.is-profilesidebar-off & {
		transform: translateX(-100%) !important;
	}
}