.o-slide{
	$_this: &;
	&--news{
		#{$_this}--item{
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	&__item{
		&-img{
			padding-bottom: percentage(460/860);
		}
	}
}