.o-author {
	&--item{
		align-items: center;

	}
	&--pic{
		width: 56px;
		height: 56px;
		overflow: hidden;
		border-radius: 100%;
		&.is-size-s{
			width: 56px;
			height: 56px;
		}
		&.is-size-m{
			width: 80px;
			height: 80px;
		}
		&.is-size-l{
			width: 100px;
			height: 100px;
		}
		/*crit*/
	}
}
