// Although the template is similar to FontAwesome,
// don't use it like .fa.fa-[icon-name].
// instead just use like v-icon-[icon-name]
// e.g. .v-icon-facebook (don't need .v-icon.v-icon-facebook)

// DON'T USE "icon" as a class. Adblock Plus apparently
// has a CSS rule to hide icon-[social-icons] elements.

/* crit:start */
@font-face {
  font-family: "g-icons";
  src: url('/www_shared/assets/fonts/g-icons.eot?09fd70');
  src: url('/www_shared/assets/fonts/g-icons.eot?09fd70#iefix') format('eot'),
    url('/www_shared/assets/fonts/g-icons.woff2?09fd70') format('woff2'),
    url('/www_shared/assets/fonts/g-icons.woff?09fd70') format('woff'),
    url('/www_shared/assets/fonts/g-icons.ttf?09fd70') format('truetype'),
    url('/www_shared/assets/fonts/g-icons.svg?09fd70#g-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconfont {
  //use !important to prevent issues with browser extensions that change fonts
  font-family: 'g-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="g-icon-"], [class*=" g-icon-"], .g-icon {
  //use !important to prevent issues with browser extensions that change fonts
  @include iconfont;
}


$icon-list: (

  angle-down: "\EA01",

  angle-left: "\EA02",

  angle-right: "\EA03",

  angle-up: "\EA04",

  arrow-mark: "\EA05",

  arrow-right: "\EA06",

  check: "\EA07",

  close: "\EA08",

  envelope: "\EA09",

  external: "\EA0A",

  facebook: "\EA0B",

  filter: "\EA0C",

  forward: "\EA0D",

  googleplus: "\EA0E",

  hamburger-close: "\EA0F",

  hamburger: "\EA10",

  instagram: "\EA11",

  linkedin: "\EA12",

  mic: "\EA13",

  next: "\EA14",

  phone: "\EA15",

  play: "\EA16",

  prev: "\EA17",

  print: "\EA18",

  search: "\EA19",

  twitter: "\EA1A",

  user: "\EA1B",

  youtube: "\EA1C",

);

@each $icon, $refKey in $icon-list{
  .g-icon-#{$icon}:before{
    content: $refKey;
  }
}
/* crit:end */

//usually this use on pseudo element
@mixin icon($iconName){
  @include iconfont;
  content: icon($iconName);
}