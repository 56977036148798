.o-ads{
	position: relative;
    overflow: initial;
	height: 646px;
	@include mq(md){
		height: 500px;
	}
	@include mq(lg){
		height: 820px;
	}
	@include mq-down(xs){
		height: 400px;
	}
	&--container{
		width: 100%;
	    height: 100%;
	    overflow: auto;
	    position: absolute;
	    top: 0;
	    left: 0;
	    clip: rect(auto,auto,auto,auto);
	    clip-path: inset(0px 0px 0px 0px);
	    &-pos{
	    	width: 100%;
		    height: 100%;
		    height: 100vh;
		    position: fixed;
		    //top: 200px;
		    top: calc(50% - 200px);
		    -moz-transform: translateZ(0);
		    -webkit-transform: translateZ(0);
		    -ms-transform: translateZ(0);
		    -o-transform: translateZ(0);
		    transform: translateZ(0);
		    margin: 0 auto;

		    @include mq(md){
				width: 844px;
		    }
		    @include mq(lg){
		    	width: 1376px;
		    	top: calc(50% - 316px);
		    }
		    @include mq-down(xs){
		    	top: calc(50% - 100px);
		    }
	    }
	}

	&--item{
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    -moz-transform: translateZ(0);
	    -webkit-transform: translateZ(0);
	    -ms-transform: translateZ(0);
	    -o-transform: translateZ(0);
	    transform: translateZ(0);
		>img{
			width: 100%; 
		}
		// @include mq(md){
		// 	height: 100%;
		// }
		// @include mq(lg){
		// 	height: 100%;
		// }
		// @include mq-down(xs){
		// 	height: 100%;
		// }

	}
	&--huge{
		@include mq(xs){
			margin-left: -4%;
			margin-right: -4%;
		}
		@include mq(sm){
			margin-left: -4%;
			margin-right: -4%;
		}
		@include mq(md){
			margin-left: -24%;
			margin-right: -24%;
		}
		@include mq(lg){
			margin-left: -30%;
			margin-right: -30%;
		}
	}

	&--fix{
		.u-bgimg{
			background-attachment:fixed;
			will-change: transform;
		}
	}

}

.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}