.o-cards{
	&__3cols{
		.o-card{
			border-right:1px solid #DEDEDE;
			@include mq-down(xs){

			}
			@include mq(sm){
				&:nth-child(2n){
					border-right:none;
				}
			}
			@include mq(lg){
				&:nth-child(2n){
					border-right:1px solid #DEDEDE;
				}
				&:nth-child(3n){
					border-right:none;
				}
			}
		}
	}

	&--alpha{
		.o-card{
			@include mq-down(xs){
				border-bottom: 1px solid $border;
			}
		}
	}
	&--list{
		.o-card--news{
			border-bottom: 1px solid $border;
			&__title{
				@include fzlh(20px, 28px)
			}
			&:last-child{
				border-bottom: 0;
			}
			@include mq-down(xs){
				&:before{
					display: none;
				}
				.o-card__body{
					max-width: 100%;
    				width: 100%;
				}
			}
		}
	}
}

.o-card{
	$_this: &;
	transition: .3s;
	display: block;
	&__media{
		height: 0;
		padding-bottom: percentage(300/400);
		display: block;
		transition: .3s;
		transform: scale(1.1);
		/*crit*/
		background-color: $gray-100;
		//background-image: url('/www_shared/assets/img/placeholder.jpg');
	}
	&__icon{
		width: 52px;
		height: 52px;
		background-color: $dark;
		color:$white;
		left:15px;
		top:15px;
		text-align: center;
		font-size: 33px;
		z-index: 1;
		/*crit*/
	}
	&__meta{
		&-title{
			color: $primary;
			letter-spacing: 3px;
			display: inline-block;
			padding-top:15px;
			margin-top: -3px;
			border-top:6px solid $yellow;
    		padding-bottom: 0;
    		margin-bottom: -5px;
    		line-height: normal;
    		min-height: 36px;
			/*crit*/
			position: relative;
    		overflow: hidden;
			z-index: 2;
			transition: .3s ease-in-out;
			@include mq-down(xs){
				margin-top: -1px;
			}

		}
		&-date{
			color:rgba(35, 31, 32, 0.65);
			line-height: 24px;
			margin-top: 10px;
			/*crit*/
		}
	}
	&__title{
		@include fzlh(26px, 36px);
		color: $dark;
		/*crit*/
		@include mq-down(xs){
			@include fzlh(20px, 28px);
		}
		a{
				color: $dark;
				/*crit*/
				&:hover{
					text-decoration: underline;
				}
		}
		&.is{
			&-md{
				@include fzlh(20px, 28px);
				/*crit*/
			}
		}
	}
	&__text{
		color: $dark;
		line-height: 28px;
		/*crit*/

		p{
			overflow: hidden;
		    text-overflow: ellipsis;
		    -webkit-box-orient: vertical;
		    display: -webkit-box;
		    -webkit-line-clamp: 3;
		    margin-bottom: 20px;
		}

		
	}
	&__head{
		&.is-rounded{
			border-radius: 100%;
		}
	}
	&__tags{
		text-transform: capitalize;
		a:after{ content: ',' }
		a:last-child:after{content: ''}
	}

	@include mq-up(md){
		&:hover{
			//text-decoration: none;
			#{$_this}__media{
				transform: none;
			}
			#{$_this}__meta{
				&-title{
					width: 100% !important;
				}
			}
		}
	}

//news
	&--news{
		color:$dark;
		&:hover{
			color:$dark;
		}
		@include mq-down(xs){
			display: flex;
			flex-wrap: wrap;
			flex-direction: row-reverse;
			justify-content: space-between;
			position: relative;
			/*crit*/
			#{$_this}__head{
				max-width: 96px;
				width: 96px;;
				padding-top: 20px;
			}
			#{$_this}__icon{
				//display: none;
				top:16px;
				left:0;
			}
			#{$_this}__body{
				max-width: 60%;
				width: 60%;
				flex-basis: 0;
				flex-grow: 1;
    			max-width: 100%;
				padding-right: 15px;
			}
			#{$_this}__title{
				@include fzlh(20px, 28px);
				letter-spacing: 1px;
			}
			#{$_this}__media:not(.o-card__video){
				height: 80px;
				padding-bottom: 90%;
			}
			#{$_this}__meta-title{
				margin-bottom: 0;
				width: auto !important;
			}
			#{$_this}__title{
				margin-bottom: 20px;
			}

			#{$_this}__text,
			#{$_this}__tags{
				display: none;
			} 
			
		}

		// @media (max-width: 320px){
		// 	#{$_this}__body{
		// 		max-width: 60%;
		// 		width: 60%;
		// 	}
		// }

		&.is-ad{
			.o-card__media{
				padding-bottom: percentage(670/500);
				transform:none;
				/*crit*/
			}
			@include mq-down(xs){
				.o-card__head{
					max-width: 100%;
    				width: 100%;
				}
			}
		}
		&.is-article{
			&:hover{
				.o-card__title{
					text-decoration: underline;
				}	
			}
		}
		&.is-audio{
			.o-card__icon{
				span:before{
					content: icon('mic');
				}
			}
		}
	}

//tile
	&--tile{
		a{
			color:$white;
			/*crit*/
		}
		#{$_this}__media{
			padding-bottom: percentage(304/400);
			/*crit*/
			transition: .3s;
		}
		#{$_this}__title{
			min-height: 64px;
			background-color: $dark;
			color:$white;
			/*crit*/
			&-icon{
				width: 64px;
				height: 64px;
				color:$white;
				text-align: center;
				background-color: $orange;
				font-size: 40px;
				display: flex;
    			align-items: center;
    			justify-content: center;
    			/*crit*/
			}
			&-name{
				font-family: $sommet;
				font-weight: 700;
				@include fzlh(32px,40px);
				padding:12px 24px;
				/*crit*/
				small{
					display: block;
					text-transform: uppercase;
					@include mq-down(xs){
						display: none;
					}
				}
			}

		}
		&.is{
			&-big{
				#{$_this}__media{
					padding-bottom: percentage(480/630);

					[data-module=scrolltrends] &{
						@include mq(lg){
							padding-bottom: 0;
							height: 479px; //fixed height as the scrolltrends
						}
					}
				}
				#{$_this}__title{
					min-height: 64px;
					/*crit*/
					&-icon{
						width: 96px;
						height: 96px;
						font-size: 60px;
		    			/*crit*/
					}
					&-name{
						@include fzlh(52px,64px);
						padding:16px 40px;
						/*crit*/

					}
					&.is-md{
						#{$_this}__title{

							&-name{
								min-width: 172px;
								@include fzlh(32px,40px);
								padding: 10px 25px;
								text-transform: capitalize;
								display: flex;
							    justify-content: center;
							    flex-direction: column;
								small{
									@include fzlh(13px,20px);
									letter-spacing: 1px;
								}
								@include mq-down(xs){
									min-width: 120px;
									@include fzlh(26px,26px);
									padding: 10px 24px;
								}
							}
							&-icon{
								width: 80px;
								height: 80px;
								font-size: 45px;
								@include mq-down(xs){
									width: 48px;
									height: 48px;
									font-size: 30px;
								}
							}
						}
					}
				}
			}
		}
		@include mq-down(xs){
			&.is{
				&-big{
					#{$_this}__title{
						min-height: 48px;
						/*crit*/
						&-icon{
							width: 48px;
							height: 48px;
							font-size: 30px;
							/*crit*/
						}
						&-name{
							@include fzlh(26px,26px);
							padding:8px 24px;
							/*crit*/
						}
					}
				}
			}
		}

	}


// ads
	&--ads{
		background-color: $primary;
		color: $white;
		display: block;
		position: relative;
		/*crit*/
		#{$_this}__head{
			padding: 15px 15px;
			position: relative;
			/*crit*/
			&:after{
				content:'';
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 12px 0 12px;
				border-color: $primary transparent transparent transparent;
				position: absolute;
				left:50%;
				margin-left: -12px;
				bottom:-8px;
				z-index: 10;
			}

			h2{
					font-size: 13px;
					font-family: $arial;
					margin-bottom: 0;
					/*crit*/
			}
		}
		#{$_this}__body{
			.o-btn--outline{
				background: transparent;
				/*crit*/
				&:hover,
				&:focus{
					background-color: #fff;
					color:$primary;
					border-color: $white;
				}
			}

		}
		#{$_this}__media{
			padding-bottom: percentage(400/285);
			transform: none;
			/*crit*/
			&:before{
					content: "";
					display: block;
					background: linear-gradient(360deg, rgba(23, 23, 23, 0.5) 0%, rgba(23, 23, 23, 0.15) 100%);
					position: absolute;
					left:0;
					bottom:0;
					right: 0;
					top:0;
					z-index: 1;
				}

			&-text{
				position: absolute;
				bottom: 0;
				z-index: 2;
				color:$white;
				width: 100%;
				padding: 36px 24px;
				/*crit*/
				h2{
					font-family: $sommet;
					@include fzlh(32px, 40px);
					/*crit*/
				}
			}
			&-logo{
				margin-top: 50px;
				/*crit*/
			}
			&:hover{
				transform: none;
			}
		}
		&:hover{
			color: $white;
		}

		@include mq-down(xs){
			#{$_this}__media{
				padding-bottom: percentage(400/312);
			}
		}


		&.is-huge{
			#{$_this}__head{
				padding: 20px 35px 15px 35px;
				/*crit*/
				&:after{
					display: none;
					/*crit*/
				}

			}
			#{$_this}__body{
				padding:0;
				/*crit*/
				h2{
					@include fzlh(32px, 40px);
					/*crit*/
				}
				.o-btn{
					position: absolute;
					right: 32px;
					bottom: 32px;
					padding-left: 28px;
					padding-right: 28px;
					font-weight: 700;
					/*crit*/
					color:$white;
					&:hover{
						color:$primary;

					}
				}
			}
			#{$_this}__media{
				padding-bottom: percentage(188/860);
				/*crit*/
				&-text{
					padding:32px;
					/*crit*/
				}

				@include mq-down(xs){
					padding-bottom: 100%;
					/*crit*/
				}
			}
			&:hover{
				#{$_this}__media{ transform: none; }
			}


			@include mq-down(xs){
				display: none;
			}
		}
	}


// engage
	&--engage{
		display: flex;
		align-items: center;
		justify-content: center;
		/*crit*/

		#{$_this}__head{
			max-width: 35%;
			width: 35%;
		}
		#{$_this}__media{
			background-color: transparent;
			/*crit*/
			transform: none;
		}
		#{$_this}__body{
			max-width: 65%;
			width: 65%;
			color:$white;
			padding-left:10px;
			position: relative;
			/*crit*/
			h2{
				border-bottom: 2px solid $skyblue;
				@include fzlh(13px, 20px);
				letter-spacing: 3px;
				text-transform: uppercase;
				font-weight: 700;
				padding-left: 20px;
				margin-bottom: 20px;
				font-family: $arial;
				padding-bottom: 10px;
				/*crit*/
			}
			p{
				margin-bottom: 0;
				@include fzlh(22px, 32px);
				padding-left: 16px;
				/*crit*/
				&:after{
					content: "";
					@include iconfont;
		  			content: icon('arrow-right');
		  			right: 0;
		  			font-size: 14px;
		  			margin-left: 10px;
		  			transition: .3s;
				}
			}

		}
		#{$_this}__media{
			padding-bottom: 100%;
			/*crit*/
		}
		&:hover{
			color: $white;
			#{$_this}__media{
				transform: none;
			}
			#{$_this}__body{
				p:after{
					margin-left: 15px;
				}
			}
		}

		&.is{
			&-md-wide{
				flex-direction: row;
				#{$_this}__body{
					max-width: 35%;
					width: 35%;
				}
				#{$_this}__body{
					max-width: 65%;
					width: 65%;
					padding-left: 40px;
					padding-right: 30px;

				}
				@include mq-down(md){
					#{$_this}__body{
						max-width: 25%;
						width: 25%;
					}
					#{$_this}__body{
						max-width: 75%;
						width: 75%;

						p{
							text-align: left;
							@include fzlh(22px, 32px);
						}
					}

				}
				@include mq-down(xs){
					flex-direction: column;
					/*crit*/
					#{$_this}__head{
						margin-bottom: 15px;
						/*crit*/
					}
					#{$_this}__body{
						max-width: 100%;
						width: 100%;
						padding-left: 0;
						/*crit*/
						h2{
							text-align: center;
							padding-left: 0;
							/*crit*/
						}
						p{
							text-align: center;
							@include fzlh(16px, 28px);
							padding-left: 0;
							padding-right: 0;
							/*crit*/
						}
					}
				}
			}

		}
		@include mq-down(md){
			flex-direction: column;
			/*crit*/
			#{$_this}__head{
				margin-bottom: 15px;
				/*crit*/
			}
			#{$_this}__body{
				max-width: 100%;
				width: 100%;
				padding-left: 0;
				/*crit*/
				h2{
					text-align: center;
					padding-left: 0;
					/*crit*/
				}
				p{
					text-align: center;
					@include fzlh(16px, 28px);
					padding-left: 0;
					padding-right: 0;
					/*crit*/
				}
			}
		}
	}

// icon
	&--icon{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
		color:$dark;
		/*crit*/

		#{$_this}__head{
			max-width: 92px;
			width: 92px;
			@include mq(md){
				max-width: 92px;
				width: 92px;
			}
			@include mq-down(sm){
				max-width: 56px;
				width: 56px;
			}
		}
		#{$_this}__body{
			flex-basis: 0;
		    flex-grow: 1;
		    max-width: 100%;
			padding-left: 16px;
			padding-right: 20px;
			/*crit*/
			p{
				margin-bottom: 0;
				font-size: 16px;
				/*crit*/
			}
			h2{
				margin-bottom: 0;
				@include mq(md){
					@include fzlh(20px, 28px);
					/*crit*/
				}
				@include mq-down(sm){
					@include fzlh(16px, 24px);
					font-family: $arial;
					/*crit*/
				}
			}
		}
		#{$_this}__media{
			padding-bottom: 100%;
			border-radius: 100%;
			transform: none;
			/*crit*/
		}
		&:after{
			content: "";
			@include vertical-center;
			@include iconfont;
  			content: icon('angle-right');
  			font-size: 24px;
  			transition: .3s;
  			right: 3px;
  			@include mq(md){
  				right: 5px;
  			}
		}

		&:hover{
			color:$black;
			text-decoration: none;

			#{$_this}__media{
				transform: none;
			}
			&:after{
				right: 0;
			}
		}
		@include mq-down(sm){
			#{$_this}__body{
				padding-left: 12px;
			}
		}

		@include mq-down(xs){
			padding-top: 8px;
			padding-bottom: 8px;
		}

	}




// alpha
	&--alpha{
		#{$_this}__title{
			h2{
				@include fzlh(41px, 52px);
				font-family: $sommet;
				text-transform: uppercase;
				font-weight: 700;
			}
		}
		#{$_this}__body{
			margin-bottom: 20px;

		}

	}

// small
	&--small{
		display: flex;
		border-top: 1px solid $border;
		padding-top: 8px;
		padding-bottom: 8px;
		/*crit*/
		em{
			background: $yellow;
			font-style: normal;
			box-shadow: 0px 4px 0px $yellow, 0px -4px 0px $yellow;
			color:$dark;
		}
		#{$_this}__head{
			flex: 0 0 91px;
    		max-width: 91px;
			/*crit*/
		}
		#{$_this}__body{
			padding-left: 15px;
			/*crit*/
		}
		#{$_this}__title{
			@include fzlh(20px, 28px);
			/*crit*/

		}
		#{$_this}__text{
			color: rgba(35, 31, 32, 0.6);
			/*crit*/
			p{
				margin-bottom: 0;
				/*crit*/
			}
			@include mq-down(xs){
				display: none;
			}
		}
	}
//profile
	&--profile{
		text-align: center;
		#{$_this}__head{
			width: 180px;
			height: 180px;
			margin-left: auto;
			margin-right: auto;
		}
		#{$_this}__media{
			padding-bottom: 100%;
		}
		#{$_this}__title{
			h2{
				@include fzlh(20px, 28px);
			}
		}
		a{
			color: $primary;
		}
	}


//set colour
	&.is{
		&-big:not(.o-card--tile){
			#{$_this}__media{
				height: 0;
				padding-bottom: percentage(455/688);
				background-repeat: no-repeat !important;
				background-size: cover !important;
			}
			#{$_this}__title{
				@include mq(xs){
					@include fzlh(20px, 32px);
				}
				@include mq(sm){
					@include fzlh(26px, 32px);
				}
				@include mq(md){
					@include fzlh(41px, 52px);
				}
			}
			@include mq-down(xs){
				#{$_this}__head,
				#{$_this}__body{
					max-width: 100%;
    				width: 100%;
				}
				#{$_this}__title{
					@include fzlh(32px, 40px)
				}
			}

		}
		&-add{
			&-arrow{
				.o-card__body{
					position: relative;
					&:after{
						content: "";
						@include iconfont;
			  			content: icon('arrow-right');
			  			right: 0;
			  			font-size: 14px;
			  			margin-left: 10px;
			  			transition: .3s;
			  			color: $primary;
			  			position: absolute;
			  			right: 0;
			  			bottom: 5px;
			  			transition: .3s;
					}
				}
				&:hover{
					.o-card__body:after{
						right: 5px;
					}
				}
			}
		}
		&-noanimate{
			.o-card__media{
				transform: none;
			}
		}
	}
}



.md-full{
			@include mq-down(md){
				margin-left: calc(50% - 50vw);
    			margin-right: calc(50% - 50vw);
				margin-top: -60px;
  				.o-card--tile .o-card__media{
  					padding-bottom: percentage(536/768);
  				}
			}
}


