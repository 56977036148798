.o-tabs {
	$_this: &; // {{ .o-tabs__nav }}
	// Settings
	$tabnav-highlight-height: 4px;
	$tabnav-highlight-color: $green;
	$tabnav-highlight-bgcolor: #fff;
	$tabcontent-bgcolor: $gray-200;
	$tabmedia-width: 247px;
	&__nav {
		background-color: $gray-100;
		//border-bottom:1px solid $gray-200;
		overflow-x: auto;
    	white-space: nowrap;
    	flex-wrap: nowrap;
		&.is-transparent{
			background-color: transparent;
			border:0;
		}
		// {{ .o-tabs__nav-link }}
		&-link {
			padding: 14px;
			border-bottom:1px solid $border;
			border-left:1px solid $border;
			border-right:1px solid transparent;
			font-size: 13px;
			&:last-child{
				//border-right:1px solid transparent;
				
				&.active{
					margin-right: -1px;	
				}
			}
			
			/* crit */
			transition: background-color .3s;
			&.active {
				border-bottom-color: transparent;
				background-color: $tabnav-highlight-bgcolor;
				color: $black;
				border-left:1px solid $gray-200;
				border-right:1px solid transparent;
				border-bottom:1px solid $white;
				//margin-bottom: -1px;
				:after {
					content: none;
					/* crit */
				}
				&:before {
					content: '';
					position: absolute;
					top: -1px;
					right: 0;
					left: 0;
					height: $tabnav-highlight-height;
					background-color: $tabnav-highlight-color;
					display: none;
					/* crit */
				} 

				&:first-child:before {
					left: -1px;
					/* crit */
				}
				&:first-child {
					border-left-color: $tabcontent-bgcolor;
				}
				&:last-child:after {
					content: '';
					position: absolute 0 -1px 0 auto;
					width: 1px;
					background-color: $tabcontent-bgcolor;
				}
				.o-tabs__nav-linksubtext{
					opacity: 0.6;
				}
			}
			&.is-disabled{
				cursor: not-allowed;
    			color: #ccc;
			}
			@include mq-down(xs) {
				&text{
					font-size: 18px;
					//color: $text;
				}
			}


		} // {{ .o-tabs__nav-item }}
		@include mq-down(xs) {
			background-color: $gray-100; 
		}


	} // {{ .o-tabs__pane }}
	&__pane {
		&:not(.active) {
			display: none;
		}
	} // {{ .o-tabs__media }}
	&__media {
		width: $tabmedia-width;
		padding-top: 45px;
		@include mq-down(sm) {
			width: auto;
			display: block;
			margin-bottom: 24px;
		}
	} // {{ .o-tabs__text }}
	&__text {
		@include mq-down(sm) {
			display: block;
		}
		#{$_this}__media+& {
			@include mq(md) {
				padding-left: 10%;
			}
		}
	}  
	// {{ .o-tabs__img }}
	&__img {
		padding-bottom: percentage(282/421);
	} // {{ .o-tabs__content }}
	&__content {
		border: 1px solid $border;
		color: $black;
		border-top: 0;
		//background-color: $white;
		/* crit */
		@include mq-down(xs) {
			// container padding size
			padding-left: 20px;
			padding-right: 20px;
		} // {{ .o-tabs__content-table }}
		&-table {
			@include mq-down(sm) {
				display: block;
			}
		}
	}
	&__btn {
		&+& {
			margin-top: 24px;
		}
	}
	@include mq-down(xs){
		nav.u-ova{
			margin-right: -16px;
		}
	}
}
