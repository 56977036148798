
.o-filter{
	$_this: &;
	&-dropdown{
		position: relative;
		/* crit */ 
		z-index: 3;
		.dropdown-toggle{
			//color: rgba(35, 31, 32, 0.65);
			color: $black;
			border-width: 1px;
			display: block;
			background-color: $gray-100;
			/* crit */ 
		}
		&-menu{
			background-color: #fff;
			border:1px solid $border2;
			padding-top: 15px;
			padding-bottom: 24px;
			overflow-y: auto;
			max-height: 282px;
			display: none;
			position: absolute;
			left: 0px; 
			transform: translate3d(0px, -1px, 0px);
			/* crit */ 
			z-index: 1;
			label{
				margin-bottom: .3rem;
				/* crit */ 
			}
		}

		&.is-open{
			.dropdown-toggle{
				color: $dark;
				border-bottom: 0;
				background-color: #fff;
				/* crit */ 
			}
			#{$_this}-dropdown-menu{
				display: block;
				border-top:0;
				/* crit */ 
			}
		}
		.ais-header{ display: none; }

		.daterange{
			.row{
				margin-left: -8px;
				margin-right: -8px;
				/* crit */ 
			}
			.col{
				padding-left: 8px;
				padding-right: 8px;
				/* crit */ 
			}
			&-action{
				.col{display: flex;}
				.o-btn--black{ width: 30%; margin-right: 8px;}
				.o-btn--primary{ width: 70% }
				@include mq(xs){
					.o-btn--black{ width: 50%; margin-right: 8px;}
					.o-btn--primary{ width: 50% }
				}
			}
		}
	}
}
.js-filter-dropdown{
	display: none;
	&.is-active{
		display: block;
	}
}

// .js-daterange{
// 	.is-op2{
// 		display: none;
// 	}
// 	&.is-date-active{
// 		.is-op1{
// 			display: none;
// 		}
// 		.is-op2{
// 			display: block;
// 		}
// 	}
// }

