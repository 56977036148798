.o-pagination{
	border-top:1px solid $border;
	/* crit */
	.o-btn{
		margin-top: 15px;
		width: 40px;
		height: 40px;
		min-height: 40px;
		line-height: 40px;
		padding-left: 0;
		padding-right: 0;
		background-color: #fff;
		color: rgba($black, 0.4);
		/*crit*/
		&:hover,
		&:focus{
			color: $black;
		}
	}
}

ul.pagination{
	margin-top: 22px;
		li.page-item{
			position: relative;
			margin:0 6px;
			.page-link{
					border:0;
					color:rgba(35, 31, 32, 0.65);
					border-radius: 0;
					position: relative;
					text-align: center;
					min-width: 40px;
					height: 40px;
					&:before{
						content: "";
						border-top:6px solid $primary;
						position: absolute;
						top:-23px;
						left:0;
						width: 100%;
						opacity: 0;
					}
					/* crit */
					&:hover,
					&:focus{
						background-color: #fff;
						color:$black;
						font-weight: 700;
					}
					&.prev,
					&.next{
						border:1px solid $border2;
						width: 48px;
						height: 48px;
						display: flex;
					    justify-content: center;
					    align-items: center;
					    color:#231F20;
					    margin-top: -6px;
					    /*crit*/
					}
			}
			&.active{
				.page-link{
						background-color: #fff !important;
						font-weight: 700;
						color: $black;
						/* crit */
						&:before{
							opacity: 1;
							/* crit */
						}
				}
			}
			&.disabled{
				.page-link{
					&.prev,
					&.next{
						border:1px solid $border;
						color: $border2;
						
					}
				}

			} 
			@include mq-down(xs){
				.ellipse{
					display: none;
				}
			}
		}
	
	li{
		&:first-child,
		&:last-child{
			position: absolute;

		}
		&:first-child{
			left: 24px;
		}
		&:last-child{
			right: 24px;
		}
		@include mq-down(sm){
			&:first-child{
				left: 18px;
			}
			&:last-child{
				right: 18px;
			}	
			&.disabled:not(.page-item){
				display: none;
			}
		}
	}
		
}

.ais-pagination{
	margin-top: 22px; 
	display: flex;
    padding-left: 0;
    list-style: none; 
    justify-content: center;
	
	&--link{
		display: block;
	    padding: .5rem .75rem;
	    margin-left: -1px;
	    line-height: 28px;
		border:0;
					color:rgba(35, 31, 32, 0.65);
					border-radius: 0;
					position: relative;
					text-align: center;
					min-width: 40px;
					height: 40px;
				
					&:before{
						content: "";
						border-top:6px solid $primary;
						position: absolute;
						top:-23px;
						left:0;
						width: 100%;
						opacity: 0;
					}
					/* crit */
					&:hover,
					&:focus{
						background-color: #fff;
						color:$black;
						font-weight: 700;
						text-decoration: none;
					}
					&.prev,
					&.next{
						border:1px solid $border2;
						width: 48px;
						height: 48px;
						display: flex;
					    justify-content: center;
					    align-items: center;
					    color:#231F20;
					    margin-top: -6px;
					    /*crit*/
					}

	}
	&--item{
		position: relative;
		margin:0 6px;


		&__previous,
		&__next{
			border: 1px solid #bababa;
		    width: 48px;
		    height: 48px;
		    display: -ms-flexbox;
		    display: flex;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -ms-flex-align: center;
		    align-items: center;
		    color: #231f20;
		    margin-top: -6px;
		    position:absolute;
		    transition: .3s;

		    &.ais-pagination--item__disabled{
		    	border: 1px solid #dedede;
    			color: #bababa;
    			.ais-pagination--link{
    				color: #bababa;
    				line-height: 28px;
    			}
		    }
		    &:hover{
		    	border-color: #231f20;
		    }
		}

		&__previous{
			left: 24px;
		}

		&__next{
			right: 24px;
		}
		@include mq-down(sm){
			&__previous{
				left: 18px;
			}

			&__next{
				right: 18px;
			} 
		}


		&__active{
			.ais-pagination--link{
				font-weight: 700;
				color:$dark;
				&:before{
					opacity: 1
				}
			}
		} 

		@include mq-down(xs){
			&__page{
				&:nth-child(n+5){
					display: none;
				}
			}
		}
	}
}



.page-link{
	border:0;
}
.page-item{
	&:first-child,
	&:last-child{
		.page-link{
			border-radius: 0;
		}
	}
}