.c-footer{
	background-color: $footer;
	color:$white;
	/*crit*/

	&__subscribe{
		&-input{
			.form-control{
				border:0;
				border-radius: 0;
				box-shadow: none;
				/*crit*/
			}
			.o-btn--black{
				border:2px solid $white;
				padding-top: 0;
				padding-bottom: 0;
				/*crit*/
			}
		}

		&-wrapper{
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			/*crit*/
			h4{
				padding-left: 80px;
				/*crit*/
				@include mq-up(lg){
					font-family: $sommet;
					@include fzlh(26px, 36px);
					font-weight: 700;
					/*crit*/
				}
			}
		}
		.o-btn--primary{
			background-color: rgba(white, 0.15);
			padding-top: 10px;
			/*crit*/
			&:hover{
				background-color: $white;
				color:#0067A8;
			}
		}
		.o-btn--outline{
			border-width: 2px;
			font-weight: 700;
			display: flex;
			&:hover{
				border-color:$white;
			}
		}
		a{
			color:$white;
			text-decoration: none;
			&:hover{
				text-decoration: none;
			}
		}
		@include mq-between(sm,md){
			&-wrapper{
				h4{
					max-width: 75%;
					@include fzlh(20px, 32px);
					padding-left: 85px;
				}
			}
		}
		@include mq-down(xs){
			&-wrapper{
				h4{
					@include fzlh(20px, 32px);
					padding-left: 75px;
				}
			}
		}

	}

	&__menus{
		.navbar{
			padding:40px 0;
			border-bottom:1px solid rgba(#ffffff, 0.15);
			@include mq-down(md){ 
				padding:20px 0 40px 0;
			}
			@include mq-down(xs){
				padding:0 0 30px 0;
			}
		}
		&-nav{
			.nav{
				&-item{
					&:first-child{
						.nav-link{
							padding-left: 0;
						}
					}
				}
				&-link{
					color: $white;
					font-weight: 700;
					&:hover,
						&:focus{
							color: $gray-400;
						}
				}
			}
			@include mq-down(md){ 
				margin-bottom: 20px;
			}
			@include mq-down(xs){ 
				width: 100%;
				flex-wrap: wrap;
				margin-bottom: 20px;
				.nav{
					&-item{
						flex: 0 0 50%;
    					max-width: 50%;
					}
					&-link{
						padding-left: 0;
					}
				}
			}
		}
		&-social{
			li{
				margin-left: 16px;
				@include mq-down(md){ 
					&:first-child{
						margin-left: 0;
					}
				}
			}
			.nav-link{
				color: $white;
				border-radius: 50px;
				width: 48px;
				height: 48px;
				background-color: #2E2E2E;
				text-align: center;
				display: flex;
				align-items: center;
				padding:0;
				justify-content: center;
				transition: .3s;
				&:hover{
					background-color: $white;
					color:$dark;
				}
			}
		}
	}

	&__secondary{
		&-tagline{
			align-items: center;
			/*crit*/

			.navbar-brand{
				background-color: #fff;
				padding: 15px 10px;
				color: $black;
				font-weight: 700;
				/*crit*/
			}
		}
		&-menu{
			//margin-left: -15px;
			//margin-right: -15px;
			padding-left: 0;
			padding-right: 0;
			font-size: 13px;
			/*crit*/
			&-bottom{
				margin-left: -15px;
				/*crit*/
				@include mq-down(xs){
					flex-direction: column;
				}
			}
			.nav{ 
				&-link{
					color: $white;
					/*crit*/
					&:hover,
					&:focus{
						color: $gray-400;
					}
				}
			}
			&-copy{
				flex-direction: column;
				width: 100%;
				color: rgba(white, 0.5)
			}

		}

	}

	
}
