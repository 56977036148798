.ais-search-box{
	position: relative;
	width: 100%;
	&--magnifier-wrapper{
		display: none;
		/*crit*/
	}
	&--reset-wrapper{
		// position: absolute;
		// right:10px;
		// top:10px;
		display: none !important;
	}
	&--reset{
		width: 30px;
		height: 30px;
		border:0;
		background-color: #fff;
		/*crit*/
	}
}

.ais{
	&-hits{
		&__empty{
			padding-left: 30px;
			padding-right: 30px;
		}
		&--item{
			.o-card__title{
				em{
					background: #ffe600;
					font-style: normal;
					box-shadow: 0 4px 0 #ffe600, 0 -4px 0 #ffe600;
				}
			}
		}
	}


	//search page
	&-header{
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	&-body{

	}
	&-refinement-list{
		&--list{
			overflow: hidden;
		}
		&--count{
			&:before{
				content: "(";
				display: inline;
			}
			&:after{
				content: ")";
				display: inline;
			}
		}
	}

	&-refinement-list--label {
	  margin-bottom: 5px;
	  display: inline-flex;
	  align-items: center;
	  text-transform: capitalize;
	  font-size: 15px;
	  cursor: pointer;

	  [type=checkbox]:checked, 
	  [type=checkbox]:not(:checked),
	  [type=radio]:checked, 
	  [type=radio]:not(:checked){
	  	left:0;
	  	position: relative;
	  	margin-right: 8px;
	  }

	  input[type=checkbox]{
	    position: relative;
	    appearance: none;
	    font-size: inherit;
	    width: 20px;
	    height: 20px;
	    background-color: #fff;
	    margin: 0;
	    left: 0;
	    color: inherit;
	    outline: none;
	    transition: 300ms ease-out;
	    border:1px solid #bababa;

	    @include iconfont;
  

	    &::after {
	      //content: icon('check');
	      display: inline-block;
	      text-align: center;
	      width: 20px;;
	      font-size: 10px;
	      content:"";
	      vertical-align: middle;
	      margin-top: -3px;
	    }
 
	    &:active {
	      transform: scale(.6);
	    }

	    + span {
	      margin-left: .35em;
	      color:#777576;
	    }
	    &:checked{
	    	background: $dark;
	    	border-color: $dark;
	    	&:after{
	    		color: $white;
	    	}
	    }
	  }


	  input[type=radio]{
	    position: relative;
	    appearance: none;
	    font-size: inherit;
	    width: 20px;
	    height: 20px;
	    background-color: #fff;
	    margin: 0;
	    left: 0;
	    color: inherit;
	    outline: none;
	    transition: 300ms ease-out;
	    border:1px solid #bababa;
	    border-radius: 20px;

	    @include iconfont;
  

	    &::after {
	      //content: icon('check');
	      display: inline-block;
	      text-align: center;
	      width: 20px;;
	      font-size: 31px;
		  content: "\2022";
		  vertical-align: middle;
		  margin-top: -13px;
		  margin-left: -1px;
		  color:$white;
	    }
 
	    &:active {
	      transform: scale(.6);
	    }

	    + span {
	      margin-left: .35em;
	      color:#777576;
	    }
	    &:checked{
	    	background: $dark;
	    	border-color: $dark;
	    	&:after{
	    		color: $white;
	    	}
	    }
	  }
	  
	  input[type=checkbox]:checked::after {
	    //content: '\f058'; // check-circle
	    content: icon('check');
	  }

	  input[type=radio]:checked::after {
	    content: '\2022'; // dot-circle
	    //content: icon('check');
	  }
	}


	&-show-more{
		position: relative;
		float: right;
		padding-left: 13px;
		font-size: 13px;
		font-style: italic;
		cursor: pointer;
		&:before{
				content: "";
				width: 10px;
				background-color: $black;
				height: 2px;
				display: inline-block;
				@include vertical-center;
				left:0;
		}
		&:after{
				content: "";
				height: 10px;
				background-color: $black;
				width: 2px;
				display: inline-block;
				@include vertical-center;
				left:4px;
		}
		&__active{
			&:after{
				display: none;
			}
		}
	}
	&-clear-all{
		&--link{
			color: $dark;
			font-weight: 700;
		}
	}

	


}
.c-module--search{
	.ais-sort-by-selector{
		.o-btn--outline{
			min-height: 40px;
		}
		//padding: 7px 18px;
	}
}


