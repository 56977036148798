.o-responsive-iframe {
    position: relative;
    padding-bottom: 62.25%;
    height: 0;
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
