.o-board{
	$_this: &;
	//background-color: #191b2d;
	&__media{
		width: 180px;
		height: 180px;
		margin: 0 auto;
		@include mq-down(sm){
			width: 114px;
			height: 114px;
		}
		@include mq-down(xs){
			width: 116px;
			height: 116px;
		}
	}
	&__icon{
		transition: .3s;
		&toggle{
			transition: .3s;
			font-size: 1.5em;
			opacity: 0;
			.u-close{
					display: none;
					transform: rotate(90deg);
				}
				@include mq-down(sm){
					opacity: 1;
				}
		}
	}
	&__img{
		padding-bottom: percentage(200/200);
		border-radius: 100%;
		/*crit*/
	}
	&__text{
		
		transition: .3s;
		@include fzlh(18px, 26px);
		/*crit*/
		.icon-toggle{display: none;}
	}
	&__role{
		color: $text;
	}
	&__title{
		font-size: 20px;
		font-family: $sommet;
		font-weight: 700;
		letter-spacing: 0.025em;
	}

	// &.active{
	// 	box-shadow: 5px 5px 0 0px $green;
	// 	#{$_this}__icon{
	// 		&toggle{
	// 			.u-add{
	// 				display: none;
	// 			}
	// 			.u-close{
	// 				display: block;
	// 			}
	// 		}
	// 	}
	// }

	&.is{
		 
		&-disabled{
			cursor: default;
			&:after{
				content:"";
				z-index: 2;
				width: 100%;
				height: 100%;
				left:0;
				bottom:0;
				top:0;
				right:0;
				display: block;
				position: absolute;
			}
			.o-board__icontoggle{
				display: none;
			}
		}
		// card with img thumb
		&-imgthumb{
			.o-board__img{
				background-color: #fff;
				background-image: none;
			}
			.o-board__icon{
				height: 100px;
				img{
					height: 100%;
				}
				@include mq-down(md){
					height: 80px;
					padding-top:10px;
					img{
						max-height: 80%;
					}
				}
			} 
			@include mq-down(sm){ 
				#{$_this}__icontoggle{
					display: none;
				}
				#{$_this}__text{
					padding-bottom: 0;
					>div{
						padding-bottom: 20px;
					}
				}
			}
			 
			&:hover{
				.o-board__icon{
					height: 80px;
				}
				@include mq-down(md){
					#{$_this}__text{
						transform: translateY(-40%);
					}
				}
				@include mq-down(xs){
					#{$_this}__text{
						transform: none; 
					}
				}
				
			}
		}
		// card with profile
		&-profile{
			min-height: 290px;
			//padding-bottom: percentage(300/262);
			.o-board__img{
				padding-bottom: percentage(184/262);
			}
			@include mq-down(sm){
				min-height: 200px;
			}

		}
		 
	}

	&__content{
		&-item{
			border-bottom: 1px solid $border;
			letter-spacing: .4px;
			p{
				@include fzlh(14px, 24px);
			}
			&--inner{
				padding: 50px;
			}
			h3{
				margin-bottom: 8px;
			}
			
			&:nth-child(2n){
				border-left: 1px solid $border;
				
				.o-board__content-item--inner{
					margin-left: 50px;
					padding-left: 50px;
					padding-right: 0;
				}
			}
			&:last-child{
				border-bottom: 0;

			}
			&:nth-last-child(2){
				border-bottom: 0;

			}
			@include mq-down(xs){
				width: 100%;
				border-bottom: 1px solid $border !important;
				border-left: 0 !important;
				&:nth-child(2n){
					.o-board__content-item--inner{
						margin-left: 0; 
					}
				}
			}
		}
	}

	@include mq-down(xs){
		display: table;
		width: 100%;
		// &__img{
		// 	padding-bottom: 67%;
		// }
		&__media,
		&__text,
		&__icontoggle{ 
		}
		&:not(.is-profile){
			&__text{
				width: 60%;
			    text-align: left;
			    right: 0;
			    top: 0;
			    padding-right: 20%;
			    display: flex;
			    justify-content:center;
			}
			&__img{
				padding-bottom: 100%;
				border-radius: 0 70% 70% 0 / 100%;
			}
			&__media{
				position: absolute;
				left: 0;
				top:0;
				width: 30%;
				height: 100%;
			}
			&__icon{
				padding-top: 25px;
				&toggle{
					width: 20%;
					right: 0; 
					opacity: 1;
					transform: translateY(-50%);
					div{
						padding-top: 10px;
					}
					.u-add{
						display: none;
					}
					.u-close{
						display: block;
						font-size: 30px;
					}
				}
			}
		} 
	}

}