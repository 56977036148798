/* crit:start */
.u {
    // Background image reset
    // no-repeat, centered, and cover size
    &-bgimg {
		@include bgimg-with-ratio;
		page-break-inside: avoid;

		&__backup {
			@media print {
				opacity: 1;
			}
		}
    }

    // Same embed responsive as Boostrap's embed-responsive, but with BEM style syntax
    &-embed {
        position: relative;
        display: block;
        height: 0;
        padding: 0;
        overflow: hidden;
        padding-bottom: 100%; //by default, it's a square

        &__item,
          iframe,
          embed,
          object,
          video {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border: 0;
        }

        &--16by9{
            padding-bottom: 56.25%;
        }
        &--4by3{
            padding-bottom: 75%;
        }
        &--soundcloud{
            padding-bottom: 168px;
        }
        &.is-video{
            &:before{
                @include iconfont;
                content: icon('play');
                @include absolute-center;
                width: 104px;
                height: 104px;
                line-height: 104px;
                background-color: $black;
                border-radius: 140px;
                color: $white;
                font-size: 70px;
                text-align: center;
                z-index: 1;
                padding-left: 6px;
            }
        }
        &.is-audio{
            
           
            .u-embed--icon{
                 @include absolute-center;
                 text-align: center;
                 z-index: 1;
                 &:before{
                    @include iconfont;
                    content: icon('mic');
                    //@include absolute-center;
                    width: 104px;
                    height: 104px;
                    line-height: 104px;
                    background-color: #171717;
                    border-radius: 140px;
                    color: $white;
                    font-size: 70px;
                    text-align: center;
                    z-index: 1;
                    margin-bottom: 24px;
                }
                &-text{
                    background: rgba($black, 0.3);
                    color: $white;
                    font-weight: 700;
                    padding: 8px 32px;
                    border-radius: 2px;
                }
            }
        }
	}

	// {{ .u-table-responsive }}
    &-table-responsive {
    	@each $breakpoint in map-keys($grid-breakpoints) {
    		$next: breakpoint-next($breakpoint, $grid-breakpoints);
    		$infix: breakpoint-infix($next, $grid-breakpoints);
    		&#{$infix} {
    			@include media-breakpoint-down($breakpoint) {
    				display: block;
    				width: 100%;
    				overflow-x: auto;
    				-webkit-overflow-scrolling: touch;
    				-ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
    				// Prevent double border on horizontal scroll due to use of `display: block;`
    				>.table-bordered {
    					border: 0;
    				}
    			}
    		}
    	}
    }

	&-vertical-center {
        @include vertical-center;
        /* crit */
    }
    &-absolute-center {
        @include absolute-center;
        /* crit */
    }
    &-horizontal-center {
        @include horizontal-center;
        /* crit */
    }

    // Ellipsis
    &-ellipsis {
        @include ellipsis;
    }

    // Collapse
    // This will show the collapsed element if it's collapsed from Boostrap class
    &-collapse {
        &-xsmax {
            @include mq(sm) {
                display: block;
                &-inline-block {
                    display: inline-block;
                }
            }
        }
        &-smmax {
            @include mq(md) {
                display: block;
                &-inline-block {
                    display: inline-block;
                }
            }
        }
        &-mdmax {
            @include mq(lg) {
                display: block;
                &-inline-block {
                    display: inline-block;
                }
            }
        }
    }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

/* crit:end */
