.o-datastatistic{
	background-color: $gray-100;
	/*crit*/
	&-number{
		color: $primary;
		@include fzlh(60px, 57px);
		font-family: $sommet;
		font-weight: 700;
		/*crit*/

		@include mq-down(xs){
			@include fzlh(25px, 35px);
		}
	}
}