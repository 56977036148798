.o-btn {
	position: relative;
	cursor: pointer;
	display: inline-block;
	/* crit */
	transition: 0.3s;

	&:hover,
	&:focus {
		color: #fff;
		outline: none;
		text-decoration: none;
		&:before {
			transform: none;
			opacity: 1;
		}
	}
	&--primary {
		background-color: $primary;
		border: 0;
		font-size: 16px;
		font-weight: 700;
		color: #fff;
		min-height: 48px;
		padding-left: 20px;
		padding-right: 20px;
		line-height: 48px;
		text-align: center;
		/* crit */
		&:before {
			background-color: darken($primary, 10%);
		}
		&:hover,
		&:focus {
			background-color: darken($primary, 10%);
		}
		span {
			font-size: 9px;
			/* crit */
		}
		em{
			font-style: normal;
		}

		.o-collapse__icon{
			right: 20px;
			&__icon-x{ height: 2; }
			&__icon-y{
				width: 2px;
			    height: 14px;
			    -webkit-transform: translate(-50%,-50%) scaleY(0);
			    transform: translate(-50%,-50%) scaleY(0);
			    transition: -webkit-transform .3s;
			    transition: transform .3s;
			    transition: transform .3s,-webkit-transform .3s;
			}
		}

		&-black {
			background-color: $black;
			&:before {
				background-color: darken($black, 10%);
			}
			&:hover,
			&:focus {
				background-color: darken($black, 10%);
			}
		}
	}
 
	&--icon {
		color: $black;
		border: 0;
		background-color: transparent;
		/*crit*/
		&:before {
			background-color: darken($primary, 10%);
		}
		&:hover,
		&:focus {
			background-color: transparent;
			color: $black;
		}
	}
 

	&--outline {
		border: 1px solid $border;
		//background-color: transparent;
		min-height: 48px;
		padding-left: 18px;
		padding-right: 18px;
		//display: flex;
		//font-weight: 700;
		/*crit*/
		span {
			font-size: 80%;
			/*crit*/
		}
		&:hover,
		&:focus {
			color: #0067a8;
			border-color: $dark;
			background-color: $white;
		}

		&2 {
			border: 2px solid currentColor;
			/* crit */

			span {
				font-size: 100%;
				/* crit */
			}

			&-black {
				border-color: $black;

				&:hover,
				&:focus {
					background-color: $black;
					color: #fff;
				}
			}
		}
	}
}
