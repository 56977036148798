/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

/* crit:start */

*,
*:before,
*:after {
	flex: 0 1 auto;
}

html {
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: scrollbar;
}

body {
	min-height: 100vh;
	//font-family: $opensans;
	font-family: 'Arial', Helvetica, sans-serif;
	color:$black;
}

a{
	transition: .3s;
}
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
	legend{
		font-family: $sommet;
		@include fzlh(20px, 28px);
		font-weight: 700;
		letter-spacing: 0.025em;
		margin-bottom: 17px;
	}
}

input {
	@include fzlh(15px, 24px);
	border-radius: 0;
	/* crit */
	&[type="search"],
	&[type="text"],
	&[type="password"],
	&[type="tel"],
	&[type="number"],
	&[type="email"] {
		box-sizing: border-box;
		width: 100%;
		padding: 12px 18px;
		border: 1px solid $border2;
		min-height: 48px;
			&::placeholder {
			  color: rgba(35, 31, 32, 0.6);
			}
	}

	&:invalid {
		// remove red border on invalid input on mozilla
		box-shadow: none;
		/* crit */
	}

	&::placeholder {
		color: rgba($text, .8);
		/* crit */
	}

	&.error {
		border-color: red;
	}

	&::-ms-clear {
		display: none;
	}
}

table:not(.u-table-unstyled) {
	width: 100%;
	td,
	th {
		padding: 15px;
		&:first-child {
			padding-left: 0;
		}
	}
	th {
		color: $text;
		text-align: left;
	}
	tr {
		border-bottom: 1px solid $border;
	}
	thead {
		tr {
			border-bottom: 3px solid $border;
		}
	}
}

select, textarea{
	// Prevent ios default box shadow and radius
	appearance: none;
	border-radius: 0;
	display: block;
	padding: 10px 18px;
	width: 100%;
	background-color: #fff;
	border: 1px solid $border2;
	min-height: 48px;
	color:$dark;

	&:disabled{
		opacity: .5;
	}

	&:focus{
		box-shadow: 0 0 10px 0 rgba(204, 218, 225, 0.7);
		background-color: #fff;
	}
}

select{
	border-radius: 0;
	background: $gray-100 url('/www_shared/assets/img/down.svg') calc(100% - 16px) 50% no-repeat;
	background-size: 20px auto;
	color:rgba(35, 31, 32, 0.65);
	&::-ms-expand {
		display: none;
	}
	&.form-control{
		background-color: $gray-100;
	}
}

textarea{
	overflow: auto;
	resize: none;
	resize: vertical;
}

img{
	max-width: 100%;
	height: auto;
}

hr{
	border:0;
	border-bottom:1px solid $border;
}

//overwrite bootstrap
@include mq-down(md){
	.container{
		padding-left: 40px;
		padding-right: 40px;
	}
	.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto{
		padding-left: 24px;
		padding-right: 24px;
	}
	.row{
		margin-left: -24px;
		margin-right: -24px;
	}
}
@include mq-down(xs){
	.container{
		padding-left: 24px;
		padding-right: 24px;
	}
	.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto{
		padding-left: 24px;
		padding-right: 24px;
	}
	.row{
		margin-left: -24px;
		margin-right: -24px;
	}
}

//form
.form{
	&-control{
		border-radius: 0;
		//color:rgba(35, 31, 32, 0.65);
		color: $dark;
		border-color: $border2;
		padding: 12px 18px;

		&.error{
			border-color: $red;
		}
	}
}
label.error,
span.error{
	color: $red;
	padding:8px 0;
	//display: inline-block !important;
	font-weight: 700;
	font-size: 14px;
	//font-family: $sommet;
}
#hiddenRecaptcha-error{ display: none !important; }
.input{
	&-group{
		&-text{
			border-radius: 0;
			background-color: #fff;
			border-right:0;
		}
		.form-control{border-left: 0}
	}
}
.dropdown{
	.dropdown-togglen{
		color: rgba(35, 31, 32, 0.65);
	}
	&-menu{
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
		font-size: 14px;
		border-radius: 4px;
		border:0;
	}
	&-item{
		padding: 5px 16px;
	}
	&-toggle:after{
		float: right;
    	margin-top: 10px;
    	border-top: .4em solid #BABABA;
	    border-right: .4em solid transparent;
	    border-bottom: 0;
	    border-left: .4em solid transparent;
	}
}
.is-open,
.show{
	.dropdown-toggle:after{
				border-top: 0;
			    border-right: .4em solid transparent;
			    border-bottom: .4em solid $dark;
			    border-left: .4em solid transparent;
			}
}

.alert{
	border-radius: 0;
	font-weight: 700;
	&-success{
	    color: $white;
	    background-color: $green;
	    border-color: $green;
	    p{
	    	margin-bottom: 0;
	    }
	}
	&-danger{
	    color: $white;
	    background-color: $red;
	    border-color: $red;
	    p{
	    	margin-bottom: 0;
	    }
	}
}
#contact-spinner img{ width: 40px }

/**** Custom Style Radio/Checkbox *****/
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked{
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label{
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  padding-top: 4px;
  font-weight: 400;
  margin-right: 20px;
  color: $black;
}
//[type="checkbox"]:not(:checked) + label,
//[type="checkbox"]:checked + label{
//  padding-top: 8px;
//}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before,
[type="checkbox"].error:not(:checked) + label:before,
[type="checkbox"].error:checked + label:before,  {
  content: '';
  position: absolute;
  left:0;
  top: 6px;
  width: 20px;
  height: 20px;
  background: $white;
  border-radius: 0px;
  border:1px solid #BABABA;
}
[type="checkbox"].error:not(:checked) + label:before,
[type="checkbox"].error:checked + label:before  {
  border-color: #BABABA;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before,
[type="radio"].error:not(:checked) + label:before,
[type="radio"].error:checked + label:before {
  content: '';
  position: absolute;
  left:0;
  top: 6px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50px;
  border:1px solid $border2;
  font-weight: 300;
}

[type="radio"].error:not(:checked) + label:before,
[type="radio"].error:checked + label:before{
  border-color: #E10000;
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after{
  @include iconfont;
  content: icon('check');
  color: $white;
  position: absolute;
  top: 5px;
  left: 0px;
  font-size: 11px;
  text-align:center;
  width: 20px;
  height: 20px;
  line-height:20px;
  background: $dark;
  border-radius: 0px;
  font-weight: 300;
  border:1px solid $dark;
  transition: all .2s;
}

[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  content: "\f111";
  font-family: FontAwesome;
  position: absolute;
  top: 6px;
  left: 0px;
  font-size: 10px;
  text-align:center;
  width: 20px;
  height: 20px;
  line-height:18px;
  color:  $dark;
  background:#fff;
  border-radius: 24px;
  border:1px solid $border2;
  transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:after{
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after{
  opacity: 1;
  transform: scale(1);
}



/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before ,
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after ,
[type="radio"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label ,
[type="radio"]:disabled + label {
  color: #aaa;
}

/* crit:end */
