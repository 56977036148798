.o-cols{
	$_this: &;
	// &__3cols{
	// 	#{$_this} {
	// 		border-right:1px solid #DEDEDE;
	// 		&:last-child{
	// 			border-right:0 !important;
	// 		}
	// 		@include mq(sm){
	// 			&:nth-child(2n){
	// 				border-right:none;
	// 			}
	// 		} 
	// 		@include mq(lg){
	// 			&:nth-child(2n){
	// 				border-right:1px solid #DEDEDE;	 
	// 			}
	// 			&:nth-child(3n){
	// 				border-right:none;
	// 			}
	// 		} 

	// 	}
	// }
	// &__2cols{
	// 	#{$_this} {
	// 		@include mq(lg){
	// 			border-right:1px solid #DEDEDE; 
	// 			&:nth-child(2n){
	// 				border-right:0;
	// 			}
	// 			&:last-child{
	// 				border-right:0 !important;
	// 			}
	// 		} 

	// 	}
	// 	&.is-bd50{
	// 		.o-cols{
	// 			border-color: rgba($white,0.5);
	// 		}
	// 	}
	// }
	// &__4cols{
	// 	#{$_this} {
	// 		border-right:1px solid #DEDEDE;  
	// 	}
	// }
	// &__6cols{
	// 	position: relative;
	// 	#{$_this} {
	// 		&:last-child{
	// 			border-right:0 !important;
	// 		}
	// 	}
	// 	&.is-hidebdr{
	// 		&:before{
	// 			content: "";
	// 			display: block;
	// 			width: 4px;
	// 			right:0;
	// 			height: 100%;
	// 			position: absolute;
	// 			top:0;
	// 			background-color: #fff;
	// 			z-index: 1;
				
	// 		}
	// 	}
	// }

	&__wrap{
		position: relative;
		#{$_this} {
			border-right:1px solid #DEDEDE; 
			&:last-child{
				border-right: 0;
			}
		}
		&:after{
			width: 2px;
			height: 100%;
			position: absolute;
			right: 0;
			top:0;
			background-color: #fff;
			content: "";
			display: inline-block;
		}
		.o-card--news{
			border-top:1px solid $border;
		}
		&.no-border{
			.o-card--news{
				@include mq(sm){
					border-top:0
				}
			}
		}
	}

	&.is{
		&-show{
			display: flex;
		}
		&-hide{
			display: none;
		}
	}

}